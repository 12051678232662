<template>
  <div class="vm-sidebar" :class="{ 'vm-sidebar-close': sidebarCollapsed }">
    <el-scrollbar wrap-class="scrollbar" style="height: 100%">
      <div class="logo">
        <router-link to="/">
          <img v-if="!sidebarCollapsed" class="large" :src="tenant.bigLogo"/>
          <img v-else class="small" :src="tenant.smallLogo"/>
        </router-link>
      </div>
      <transition>
        <el-menu active-text-color="#0747FD" :collapse="sidebarCollapsed" :default-active="$route.name">
          <template v-for="(menu, index) in menus">
            <el-submenu v-if="menu.children && menu.children.length > 1" :key="index" :index="index + ''">
              <template slot="title">
                <i class="iconfont" :class="[sidebarCollapsed ? 'icon-lg' : sidebarCollapsed, menu.icon]"
                  style="padding-right: 5px"></i>
                <span>{{ $t("route." + menu.title) }}</span>
              </template>
              <el-menu-item v-for="(child, childIndex) in menu.children" :key="childIndex" :index="child.name"
                @click="goto(child)">
                <!--                <i class="iconfont iot-circle"></i>-->
                {{ $t("route." + child.title) }}
              </el-menu-item>
            </el-submenu>
            <el-menu-item v-else :key="index" :index="menu.children[0].name" @click="goto(menu.children[0])">
              <i class="iconfont" :class="[sidebarCollapsed ? 'icon-lg' : sidebarCollapsed, menu.icon]"
                style="padding-right: 5px"></i>
              <span>{{ $t("route." + menu.children[0].title) }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </transition>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import tenant from "@/util/tenant";

export default {
  components: {},
  data() {
    return {
      tenant: tenant.get(),
    };
  },
  computed: mapGetters({
    sidebarCollapsed: "sidebarCollapsed",
    menus: "menus",
  }),
  methods: {
    goto(menu) {
      if (menu.newPage) {
        window.open("#" + menu.path);
      } else {
        this.$router.push({ path: menu.path });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../style/variables.scss";

.vm-sidebar {
  transition: width 0.25s ease-in-out;
  width: $sidebar-width;
  z-index: 10;

  .el-menu {
    border: none;
  }

  .icon-lg {
    font-size: 22px;
  }

  .iconfont {
    color: #000;
  }

  .is-active {
    .el-submenu__title {
      color: #0747FD;
    }

    .iconfont {
      color: #0747FD;
    }
  }

  .logo {
    text-align: center;
    padding-top: 4px;
    .large {
      width: 200px;
    }

    .small {
      width: 45px;
    }
  }

  .scrollbar {
    height: 100vh;
    overflow-x: hidden;
  }
}

.vm-sidebar-close {
  width: $sidebar-width-close;
}
</style>
