export default {
  lang: "en",
  login: {
    loginFailed: "Login Failed",
    name: "STEP eCloud",
    login: "Login",
    logout: "Logout",
    welcome: "Welcome to ",
    account: "Account",
    password: "Password",
    forgotPassword: "Forgot Password",
    app: "APP",
    qrCodeApp: "Download Elevator Cloud APP",
    chromeDown: "Download Now",
    chromeLogin: "Suggest logging in using Chrome browser",
    phone: "Phone",
    verify: "Verify Code",
    newPassword: "New Password",
    submit: "submit",
    sendMSG: "Send",
    resend: "Resend",
    tips: {
      account: "Please Enter Account",
      password: "Please Enter Password",
      notNull: "Cannot be none",
      logout: "Are you sure you want to log out ?",
    },
  },
  common: {
    fillThreeDays: "Please fill in the occurrence time first (within three days).",
    withinThreeDays: "Please fill in the time within three days!",
    powerTime: "Please fill in the power off time first (within seven days).",
    alarmTime: "Please fill in the alarm time first (within seven days).",
    faultTime: "Please fill in the failure time first (within seven days).",
    sevenTime: "Please fill in the time within seven days",
    keywordSearch: "Keyword Search",
    loading: "Loading",
    loadingFailed: "Loading failed, click Refresh",
    noData: "No data, click Refresh",
    hint: "Hint",
    failedServer: "Failed to connect to server",
    requestError: "Request server error",
    add: "Add",
    search: "Search",
    clear: "clear",
    advanceSearch: "Advance Search",
    pleaseEnter: "Please Enter",
    pleaseSelect: "Please Select",
    chooseAnArea: "Choose an Area",
    select: "Select",
    import: "Import",
    importType: "Please upload .XLSX file",
    importSuccess: "Import Success",
    importFailure: "Import Failure",
    operation: "Operation",
    confirm: "OK",
    cancel: "Cancel",
    delete: "Del",
    generate: "Generate",
    remove: "Remove",
    detail: "Detail",
    setting: "setting",
    isDelete: "Do you want to delete",
    setBlack: "Are you sure to blacklist the selected gateway device ?",
    cancelBlack: "Confirm the selected device gateway. Is the blacklist removed?",
    isGenerate: "Do you want to generate",
    isAdd: "Do you want to add",
    edit: "Edit",
    save: "Save",
    yes: "Yes",
    no: "No",
    startDate: "Start Date",
    endDate: "End Date",
    beginTime: "Begin Time",
    endTime: "End Time",
    to: "To",
    confirmTip: "Do you want to delete this line",
    filter: "Filter",
    tips: "Tips",
    more: "More",
    less: "Less",
    set: "Set",
    tip: {
      baiduMapError: "Could not load BaiDu Map",
      googleMapError: "Could not load Google Map, possibly due to network issues",
      getDataError: "Get Data Error",
      notNull: " Cannot be none",
      needNum: "Please enter a number greater than 0",
      needNum1: "Please enter a number >= 0",
      saveSuccess: "Save Success",
      completeFloorInformation: "Please complete the floor information",
      optional: "Factory number and elevator register code to fill in at least one!",
      saveError: "Save Error",
      settingError: "Setting Error",
      deleteSuccess: "Delete Success",
      settingSuccess: "Setting Success",
      deleteError: "Delete failure",
      addSuccess: "Add Success",
      addError: "Add failure",
      submitError: "Please revise and submit",
      operationSuccess: "Operation Success",
      operationFailure: "Operation failure",
    },
  },
  headerBar: {
    app: "APP",
    globalMute: "Global Mute",
    mute: "Mute",
    unavailable: "Unavailable",
    connect: "Connecting...",
    nonsupport: "Nonsupport",
    connectionFailed: "Connection Failed",
    available: "Available",
    newConnection: "New Connection",
    landed: "Other places landed",
    setting: "Account Setting",
    logout: "Logout",
    uiSetting: "UI Setting",
    board: "Dashboard",
    recentMaintWork: "The following elevators' maintenance work is about to expire",
    recentContract: "The following contracts is about to expire",
    recentYearCheck: "The following elevators' annual inspection will expire soon",
  },
  home: {
    lifts: "Lifts",
    users: "Users",
    liftsLocation: "Lifts Location",
    onlineLifts: "Online",
    repairLifts: "Repairing",
    parts: "Devices",
    info: "Basic Information",
    elevatorStatus: "Elevator Status",
    faultType: "Fault Types",
    timeFrame: "Time Frame",
    documents: "Documents",
    realTimeInfo: "Real-time Info",
    events: "Events",
    faults: "Faults",
    alarms: "Alarms",
    mainPowerLoss: "Power Failure",
    enterStopMode: "Stop Mode",
    noFaults: "No faults within the cycle",
  },
  route: {
    camera: "ZhiLian Camera",
    maintPlan: "Maintenance Plan",
    hint: "Hint",
    home: "Home",
    account: "Account",
    notice: "Notice",
    uiSetting: "UI Setting",
    noticeDetail: "Notice Detail",
    elevator: "Elevator",
    peopleCounting: "People Counting",
    elevatorInfo: "Elevator Info",
    device: "Device Status",
    danger: "Danger",
    elevatorUser: "Elevator User",
    monitor: "Monitor",
    useUnitMonitor: "Group Monitor",
    map: "GIS Map",
    singleMonitor: "Single Monitor",
    videoCall: "Video Call",
    emergencyRepair: "Emergency Repair",
    repairJob: "Repair Order",
    annualInspection: "Annual Inspection",
    annualInspectionRecord: "Annual Inspection",
    userRole: "User Role",
    user: "User",
    role: "Role",
    baseInfo: "Base Info",
    useUnit: "Use Unit",
    gatewayVersion: "Gateway Version",
    thirdPartyPlatform: "ThirdParty Platform",
    messagePush: "Message Push Records",
    parts: "Fittings Management",
    partsType: "Accessories Type",
    company: "Company",
    qualitySupervision: "Quality Supervision",
    videoUser: "Video User",
    dtuTest: "Device Gateway Test",
    floorDisplay: "Floor Display",
    faultTemplate: "Fault Template",
    developerPermission: "Developer",
    userDebugInfo: "Debugger",
    potentialFaultsetting: "Fault Setting",
    deviceChangeRecord: "Old Event Records",
    mb: "Event Records",
    faultRecords: "Fault Records",
    integration: "Integration Set",
    elevatorMaintenance: "Elevator Maintenance",
    maintenanceWorkOrder: "Maintenance Order",
    maintenanceSetting: "Maintenance Setting",
    upkeep: "Upkeep",
    accessPlatform: "Access Platform",
    msgSend: "SMS Send Records",
    userTakeRecord: "Call Ladder Records",
    msgTemplate: "SMS configuration",
    system: "System",
    event: "Event",
    ztVersion: "ZT Version",
    equip: "Gateway Device",
    shanghai: "shanghai Docking",
    wuxi: "wuxi Docking",
    chongqing: "ChongQing Docking",
    shenyang: "shenyang Docking",
    elevatorNetApply: "Net Access Apply",
    newMsgSend: "Message Records",
    deviceGateway: "Device Gateway",
    Operations: "Operations",
    kanban: "看板展示",
    userLoginLog: "UserLogin Log",
    userOperateLog: "UserOperate Log",
    elevatorSetting: "Elevator Setting",
    systemSetting: "System Setting",
    contract: "Contract",
    tpLinkVideo: "TPLink Video",
    eocdPotential: "Eocd Potential",
  },
  camera: {
    upgradeSuccess: "Upgrade Send Success",
    upgrade: "Device Upgrade",
    upgradeFile: "Upgrade File",
    deviceCode: "Device Code",
    screenshot: "Screen Shot",
    overlayOSD: "Overlay OSD",
    pictureQuality: "Picture Quality Level",
    videoWidth: "Video Width",
    videoHeight: "Video Height",
    deviceUpgrade: "Device Upgrade",
    deviceRestart: "Device Restart",
    setTime: "Time setting",
    doorParameterSetting: "Door Parameter Setting",
    basicParameterSetting: "Basic Parameter Setting",
    motorPetalParam: "Motor Petal Param",
    signalValue: "Signal Value",
    lastUpdateStatus: "Last Update Status",
    notUpgraded: "Not Upgraded",
    upgrading: "Upgrading",
    success: "Success",
    fail: "Failed",
    restart: "Restart",
    lastUpdateTime: "Last Update Time",
    lastLoginTime: "Last Login Time",
    lastKeepaliveTime: "Last Keepalive Time",
    snapshotRecord: "Snapshot Record",
    deviceIsOffline: "The device status is offline !",
    makeSureDevice: "Make sure to number the device code",
    restartSuccessfully: "Restart Successfully",
    lastOnlineTime: "Last Online time",
    commonParamSetting: "Common Param Setting",
    serverIp: "Server Ip",
    serverPort: "Server Port",
    doorDetectionEnable: "Door Detection Enable",
    longestOpenTime: "Longest OpenTime",
    doorOpeningTimes: "Door Opening Times",
    alarmDelay: "Alarm Delay",
    visitorFloor: "Visitor Floor",
    check: "Check",
    noCheck: "No Check",
    imageRecord: "Image Record",
    snapshotType: "Snapshot Type",
    snapshotTime: "Snapshot Time",
    manualCapture: "Manual Capture",
    doorRepeatedly: "Door Repeatedly",
    blockDoorLongTime: "Block Door Long Time",
    motorEnterLadder: "Motor Enter Ladder",
    petEnterElevator: "Pet Enter Elevator",
    blockingDoor: "Blocking Door",
    picture: "Picture",
    elevatorDoorEnable: "Elevator Door Enable",
    audibleAlarmEnable: "Audible Alarm Enable",
    lightAlarmEnabled: "Light Alarm Enabled",
    enabledModels: "Enabled Models",
    ladderMaximumTime: "Ladder Maximum Time",
    elevatorEnableTimePeriod: "Elevator Enable Time Period",
    enableFloor: "Enable Floor",
    efficient: "Efficient",
    motorCarCheck: "Motor Car Check",
    bicycleCheck: "Bicycle Check",
    doubleDetection: "Double Detection",
    time: "Time",
    selectDateTime: "select date time",
    supportExtension: "support extension",
    clickToUpload: "Click To Upload",
  },
  user: {
    user: "User",
    labelWidth: "180px",
    userNameRule: "Username is 4~25 characters, can only contain English letters, numbers, @, dots, underscores",
    username: "Username",
    password: "Password",
    messageSendType: "Verification Code Send Type",
    type: "Type",
    name: "Name",
    phone: "Phone",
    repair: "Faults SMS",
    maintenance: "Maintenance SMS",
    alarm: "Alarm SMS",
    company: "Company",
    lastLoginTime: "Last Login Time",
    quality: "Quality",
    mail: "Mail",
    maintainer: "Maintainer",
    messageControl: "Message Control",
    changePwdState: "Change Password ",
    resetPassword: "Reset Password",
    unselectedRole: "Unselected Role",
    selectedRole: "Selected Role",
    afterSale: "After Sale",
    clientUser:"Client User",
    reset: "Reset",
    is: "",
    types: {
      0: "Own",
      1: "Company Admin",
      2: "Company Normal",
      9: "Normal",
    },
    tip: {
      mail: "Please enter the correct mail",
      phone: "Please enter the correct phone",
      password: "Password must contain both letters and numbers and 8-30 length",
      tips: "The password is a123456b",
      successful: "Reset password successful",
      failed: "Password reset failed",
    },
    account: {
      baseInfo: "Base Info",
      oldPassword: "Old Password",
      newPassword: "New Password",
      confirm: "Confirm",
      tip: {
        confirm: "Two input password inconsistencies",
      },
    },
  },
  userTakeRecord: {
    name: "Name",
    value: "Value",
    callElevatorStatistics: "Call Elevator Statistics",
    recordTime: "Record time",
    username: "Username",
    registerMobile: "Register mobile",
    implementationElevator: "Implementation Elevator",
    userLocation: "User location",
    currentFloor: "Current floor",
    destinationFloor: "Destination floor",
    operationType: "Operation type",
    remark: "Remark",
    userPhone: "User phone",
    callElevatorTimes: "Call elevator times",
  },
  contract: {
    uploadTypeLimit: "Only files with suffixes jpg, png, bmp, jpeg, xlsx, xls, docx, doc can be uploaded",
    uploadSuccess: "Upload Succeeded",
    uploadFailure: "Upload Failed",
    uploadSizeLimit: "The upload file size cannot exceed 10M",
    download: "Download",
    paymentMethod: "Payment Method",
    elevatorSelect: "Elevator Selected ",
    tower: " Tower",
    appendix: "Appendix",
    fileInfo: "Appendix Info",
    addFile: "Add Appendix",
    fileName: "Appendix Name",
    uploadTime: "Upload Time",
    uploader: "Uploader",
    labelWidth: "160px",
    contractInfo: "Contract Info",
    check: "Check",
    upload: "Upload",
    delete: "Delete",
    branchName: "Branch Agency Name",
    contractNo: "Contract No.",
    branchAgency: "Branch Agency",
    packagingForm: "Packaging Form",
    contractSource: "Contract Source",
    expiredStatus: "Expired Status",
    contractStartDate: "Contract Start Date",
    contractEndDate: "Contract End Date",
    copy: "Copy",
    batchDelete: "Batch Delete",
    importContract: "Import Contract",
    siteName: "Site Name",
    remindExpired: "Remind Expired",
    beExpired: "Be Expired",
    expiresMonth: "Expires in one month",
    expiresThreeMonths: "Expires in three months",
    contractPrice: "Contract Price(/RMB)",
    selectOneContract: "Please select at least one contract",
    voidedContract: "Are you sure to void the selected contract, the voided contract cannot be recovered!",
    firstSelectContract: "Please select a contract first",
    mostOneContract: "Select at most one contract",
    inspectionUndertaker: "Inspection Undertaker",
    speedLimiterUndertaker: "SpeedLimiter Undertaker",
    brakeTestFeeUndertaker: "Brake Test Fee Undertaker",
    freeAccessoriesQuota: "Free Accessories Quota(/RMB)",
    monthlyUnitPrice: "MonthlyUnit Price(/RMB)",
    agencyFees: "Agency Fees(/RMB)",
    contactName: "Contact",
    contactInformation: "Telephone",
    isSubcontract: "Whether Subcontract",
  },
  elevator: {
    count: "counts",
    authorizationTime: "Authorization Time",
    callElevator: "Call Elevator",
    yearMaint: "Year Maint",
    autoGenerate: "Auto generate daily, weekly, and monthly reports",
    checkTemplates: "Check Templates",
    belongCompany: "Belong Company",
    elevatorsOnline: "The following elevators are offline or online",
    isHomeUse: "Is Home Use",
    ownerPhoneNo: "Owner Phone Number",
    monitorElevators: "Monitor up to eight elevators at a time",
    selectElevator: "Please select the elevator first",
    selectFloorDisplay: "Please select the floor display first",
    selectFaultTemplate: "Please select the fault template first",
    parameterEmpty: "The parameter cannot be empty",
    selectMaintenance: "Please select a maintenance enterprise",
    selectPropertyCom: "Please select a Property enterprise",
    maintenanceWorker: "Please select at least one maintenance worker",
    sameMaintenanceWorker: "Can not choose the same maintenance worker!",
    samePropertySafetyOfficer: "Can not choose the same safety officer!",
    samePropertySafetyDirector: "Can not choose the same safety director!",
    floorComparisonTable: "Floor Comparison Table",
    alarmDelayTime: "Alarm Delay Time",
    maintenanceMan: "Maintenance Man",
    dockingPlatform: "Docking Platform",
    exportExcel: "Export Excel",
    labelWidth: "220px",
    labelWidthList: "150px",
    labelWidthAlarm: "100px",
    stopStatus: "Current Floor Stop Status",
    yes: "Yes",
    No: "No",
    confirmChoice: "Confirm Choice",
    timeOfOccurrence: "Time of Occurrence",
    gatewayPowerDown: "Gateway Power Down",
    nonGateAreaStop: "Non Gate Area Stop",
    repair: "Repair",
    powerDownTime: "Power Down Time",
    whetherToRestore: "Whether to Restore",
    alarmBell: "Alarm Bell",
    enterOutOfService: "Enter Out of Service",
    powerFailure: "Power Failure of Main Power Supply",
    floorQr: "Floor QR code Download",
    elevator: "Elevator",
    addElevator: "Add the elevator",
    no: "Elevator No.",
    address: "Address",
    person: "Person",
    elevatorType: "Elevator Type",
    registerCode: "Register Code",
    name: "Name",
    brand: "Brand",
    model: "Model",
    maintWorker: "Maint Worker",
    maintType: "Maint Type",
    safetyOfficer: "Safety Officer",
    safetyDirector: "Safety Director",
    safetyMainer: "Safety Mainer",
    elevatorNo: "Elevator No.",
    dtuCode: "DTU Code",
    nodeCode: "Node Code",
    dtuModel: "DTU Model",
    simCardNo: "SIM Card No.",
    faultCode: "Fault Code",
    iccid: "ICCID",
    exFactoryNo: "Ex-Factory No.",
    controlCabinetLeaveNo: "Control Cabinet Leave No.",
    exFactoryNoWx: "Ex-Factory No WX",
    outerDeviceWx: "OuterDevice WX",
    statusWx: "Status WX",
    maintPeriod: "Maint Period(days)",
    whereUsed: "Where Used",
    faultTemplate: "Fault Template",
    deviceGateway: "Device Gateway",
    notInstalled: "Not Installed",
    installed_plug: "Installed(plug)",
    installed_link: "Installed(link)",
    videoChannelId: "Car Video Channel Id",
    videoUrl: "Car Video Url",
    motorRoomVideoUrl: "Car Video Url FLV",
    video: "Video",
    installed: "Installed",
    joinStatus: "Join Status",
    isParallel: "Is Parallel",
    openStatus: "Open Status",
    open: "open",
    notOpen: "Not Open",
    halfOpen: "Half Open",
    onlineStatus: "Online Status",
    accessPlatform: "Access Platform",
    activated: "Activated",
    notActivated: "Not Activated",
    useStatus: "Use Status",
    useUnit: "Use Unit",
    property: "Property",
    maintenanced: "Maintenanced",
    propertyCom: "Property",
    qualityOrgan: "Quality Organ",
    manufactured: "Manufactured",
    installationEnterprise: "Installation Enterprise",
    reconstructed: "Reconstructed",
    locCode: "Location code",
    exFactoryDate: "Ex-Factory Date",
    installationDate: "Installation Date",
    joinDate: "Join Date",
    simExpireDate: "SIM Expire Date",
    signalType: "Signal Type",
    serviceDate: "Service Date",
    test: "Test",
    check: "Check",
    floors: "Floors",
    ratedSpeedOfEscalator: "Rated Speed of Escalator(m/s)",
    riseOfEscalator: "Rise of Escalator(m)",
    angleOfEscalator: "Angle of Escalator(degree)",
    widthOfEscalator: "Width of Escalator(m)",
    ratedSpeedOfMovingWalk: "Rated Speed of Moving Walk(m/s)",
    lengthOfMovingWalk: "length of Moving Walk(m)",
    angleOfMovingWalk: "Angle of Moving Walk(degree)",
    widthOfMovingWalk: "Width of Moving Walk(m)",
    highestFloorOfElevator: "highest Floor of Elevator(floor)",
    ratedSpeedOfElevator: "Rated Speed of Elevator(m/s)",
    ratedSpeedOfElevatorRule: "Rated Speed of Elevator",
    ratedLoadOfElevator: "Rated Load of Elevator(kg)",
    floorDisplayTemplate: "Floor Display",
    actualFloor: "Actual Floor",
    showFloor: "Show Floor",
    displayedFloor: "Displayed Floor",
    currentFloorCode: "Current Floor Code(A0)",
    faultFloorCode: "Fault Floor Code(MC)",
    addFloorDisPlay: "Add floor disPlay",
    peopleStuckAlarmDelayTime: "People Stuck Alarm Delay Time(s)",
    notAvailableAlarmDelayTime: "Not Available Alarm Delay Time(s)",
    finishDelayTime: "Finish Delay Time(s)",
    longitude: "Longitude",
    latitude: "Latitude",
    joined: "Joined",
    notJoined: "Not Joined",
    useStatus_1: "Using",
    useStatus_2: "Dumping",
    useStatus_3: "Unused",
    useStatus_4: "Cancellation",
    maintenanceBySelf: "Maintenance by Self",
    maintenanceByOthers: "Maintenance by Others",
    baseInfo: "BaseInfo",
    production: "Production",
    using: "Using",
    maintenance: "Maintenance",
    system: "system",
    fault: "fault",
    enterprise: "Enterprise",
    parameters: "Parameters",
    extensionParameters: "Extension Parameters",
    iot: "IoT",
    modifyLocation: "Modify Location",
    elevatorParts: {
      id: "ID",
      elevatorID: "Elevator Id",
      partsID: "Parts Id",
      elevatorParts: "elevator Parts",
      add: "add",
      category: "category",
      number: "number",
      name: "name",
      serialNumber: "serial Number",
      floor: "Floor",
      status: "Authentication Status",
      activated: "activated",
      notActivated: "Not Activated",
      remove: "Remove",
      activateTime: "Activate Time",
      activator: "activator",
      createTime: "Create Time",
      replace: "replace",
      replacementRecord: "replacement Record",
      oldParts: "old Parts",
      newParts: "new Parts",
      replacementTime: "replacement Time",
    },
    add: "Add",
    generalParameters: "General Parameters",
    floorDisplay: "Floor Display",
    alarmParameters: "Alarm Parameters",
    location: "Elevator Location",
    record: "Record",
    emergencyRecord: "EmergencyRecord",
    maintenanceRecord: "Maintenance Record",
    yearCheckRecord: "Year Check Record",
    faultRecord: "Fault Record",
    potentialFault: "Potential Fault",
    device: "Device",
    runTimes: "run Times",
    gradeAlarming: "Grade Alarming",
    maintComSelectTip: "Please choose the maintenanced first",
    propertyComSelectTip: "Please choose the property first",
    elevatorDebuggingPlatform: "Elevator debugging platform",
    elevatorCheck: "Elevator Check",
    lock: "lock the elevator",
    unlock: "unlock the elevator",
    Lock: "Lock",
    Unlock: "Unlock",
    closeAuth: "Close Auth",
    openAuth: "Open Auth",
    activationBinding: "Activation binding",
    AllBoards: "All the boards in the system",
    elseBoards: "else(ex-board etc.)",
    simCardBinding: "Sim card binding",
    groupControlBoard: "Group control board",
    frequencyConverter: "Frequency converter",
    carBoard: "Car board",
    sedanRoof: "Sedan roof",
    callBoard: "Call board",
    changeProbationPeriod: "Change the probation period",
    escalatorLock: "Escalator Lock",
    escalatorUp: "Escalator Up",
    escalatorDown: "Escalator Down",
    day: "day",
    hour: "hour",
    openDebugAuth: "Open debug Auth",
    grade: "Grade",
    time: "Time",
    stopOrStart: "Stop or Start",
    lockOrUnlock: "Lock or Unlock contract No.",
    contractNo: "Contract No.",
    refresh: "refresh",
    operationRecord: "Operation record",
    type: "type",
    order: "order",
    status: "status",
    operator: "operator",
    executing: "executing",
    success: "success",
    error: "error",
    overTime: "overTime",
    copy: "Copy",
    batchAdding: "Batch adding elevator",
    batchCopy: "Batch copy this elevator",
    addNumber: "Add number",
    batchModification: "Batch Modification",
    importSimInfo: "Import Sim Info",
    downloadSimTemplate: "Download Sim Info Import Template",
    uploadType: "Please upload. XLSX file type",
    importSuccess: "Import Success",
    importFailure: "Import Failure",
    tip: {
      maintPeriod: "maintPeriod must be more than 0",
      operateSuccess: "operate Success",
      operateError: "operate Error",
      atLeastOne: "Please choose at least one item",
      daysAndHours: "Please fill in the days and hours correctly",
      gradeAndTime: "Please fill in grade and time correctly",
      chooseFirst: "Please choose first",
      want: "Do you want to ",
      contractNo: "The contract NO can not be empty",
      batchAddition: "Batch addition does not need to be filled in",
      needRegNO: "Can't be empty, please fill the inEx-Factory No.",
    },
  },
  deviceGateway: {
    phone: "Call Phone",
    setPhone: "Set Phone",
    editBlack: "Edit Blacklist",
    downloadingTemplate: "Download Import Template",
    blacklist: "Blacklist",
    supportProtocol: "Support Protocol",
    bound: "Bound",
    unbound: "Unbound",
    selectBlacklist: "Select the device gateway for which you want to set the blacklist",
    selectSupportProtocol: "Select the device gateway for which you want to set the protocol",
    labelWidth: "180px",
    editDevice: "Edit Device Gateway",
    import: "Import",
    dtuCode: "DTU Code",
    deviceModel: "Device Model",
    signalType: "Signal Type",
    simCode: "SIM Code",
    simICCID: "SIM ICCID",
    signalStrength: "Signal Strength",
    softVer: "Soft Version",
    hardVer: "Hard Version",
    bindState: "Bind State",
    temperature: "Device Temperature",
    devicePower: "Device Power",
    linkStatus: "Motherboard Link Status",
    blackStatus: "Black Status",
    blackYes: "Yes",
    dtuStatus: "Online Status",
    expirationTime: "SIM Card Expiration Time",
    updateTime: "Last Update Time",
    inNetState: "InNet State",
    communicationFailed: "Communication Failed",
    communicationNormal: "Communication Normal",
    dtuOnline: "Online",
    dtuOffline: "Offline",
    onlineState: "Online State",
    onlineUpdateTime: "Online Update Time",
    invalid: "Invalid",
    accessTime: "Access Time",
    no: "No",
    yes: "Yes",
  },
  elevatorNetApply: {
    floorMappings: "Floor Comparison",
    proName: "Project Name",
    createSource: "Create Source",
    addFloorMappings: "Please Add Floor Information",
    uploadType: "Please upload. XLSX file type",
    importSuccess: "Import Success",
    importFailure: "Import Failure",
    importOnly: "You can import only one file at a time",
    generateSuccess: "Generate success",
    labelWidth: "230px",
    inGenerated: "In the generated",
    elevatorName: "Elevator Name",
    reset: "Reset",
    search: "Search",
    add: "Add",
    importElevator: "Import Elevator",
    downloadTemplate: "Download Template",
    dtuCode: "DTU Code",
    useUnitDistrict: "District",
    useUnitAddress: "Address",
    maintComName: "Maintenance Company",
    manufactured: "Manufacture Company",
    createCom: "Create Company",
    createCreditCode: "Create Company Credit Code",
    maintCreditCode: "Maintenance Company Credit Code",
    maintEmpName: "Maintenance personnel",
    maintEmpPhone: "Phone",
    propertyCom: "Property Company",
    brand: "Brand",
    load: "Load",
    dtuModel: "Dtu Model",
    nodeCode: "Node Code",
    regCode: "Register Code",
    sim: "SIM",
    elevatorModel: "Elevator Model",
    controlModel: "Control Model",
    elevatorType: "Elevator Type",
    speed: "Speed",
    exFactoryDate: "Factory Date",
    installationDate: "Installation Date",
    videoChannelId: "Camera Id",
    factoryNumber: "Ex-Factory Number",
    companyInfo: "Company Information",
    elevatorInfo: "Elevator Information",
    useUnit: "UseUnit",
    operate: "Operate",
    generate: "Generate",
    generated: "Generated",
    edit: "Edit",
    delete: "Delete",
    floorLocation: "Floor Location",
    displayCode: "Display Code",
    addElevatorNetApply: "Add ElevatorNetApply",
    editElevatorNetApply: "Edit ElevatorNetApply",
    pleaseEnter: "Please Enter",
    notNull: "Cannot be empty",
    cancel: "cancel",
    save: "save",
  },
  workOrder: {
    workOrderType: "Order Type",
    humanCauses: "Human Causes",
    externalCauses: "External Causes",
    doorSystem: "Door System",
    tractionSystem: "Traction System",
    guidanceSystem: "Guidance System",
    controlSystem: "Control System",
    electricalSystem: "Electrical System",
    protectionDevice: "Safety Protection Device",
    faultAnalysis: "Fault Analysis",
    liftCar: "LiftCar",
    orderConfirm: "Order Confirm",
    faultReason: "Fault Reason",
    handleMethod: "Handle Method",
    extensionNumber: "Extension Number:",
    choiceDate: "Please fill in the correct date!",
    choicePrice: "Please fill in the correct price!",
    faultCode: "Fault Code",
    screenshot: "Screenshot",
    elevatorGateways: "The following elevator gateways are powered off",
    elevatorsRepairs: "The following elevators are in urgent repair",
    elevatorAlarm: "The following elevator alarm bell alarm",
    followingOverdue: "The following annual inspections are overdue",
    maintenanceExpire: "The following maintenance is about to expire",
    pleaseSelect: "Please Select Elevator",
    elevator: "Elevator",
    workOrder: "Repair Job",
    labelWidth: "140px",
    information: "Work order information",
    workOrderNo: "No.",
    workOrderStatus: "Repair Job Status",
    alarmType: "Alarm Type",
    alarmType_0: "Not Available",
    alarmType_1: "People Stuck",
    alarmType_2: "Alarm Bell",
    alarmType_3: "Serious",
    deviceReasonTrapped: "Device Reason Trapped",
    deviceReasonSafe: "Device Reason Safe Problem",
    elevatorDamageOrFault: "Elevator Damage Or Fault",
    minorIssues: "Minor Issues",
    userReasonNoTrapped: "User Reason No Trapped",
    userReasonTrapped: "User Reason Trapped",
    manualAlarm: "Manual Alarm",
    automaticAlarm: "Auto Alarm",
    callRecord: "Call Record",
    faultDesc: "Describe the fault situation during the call",
    alarmTime: "Alarm Time",
    deviceCode: "Device Code",
    acceptor: "Acceptor",
    repairPeople: "Repair People",
    repairMethod: "Repair Method",
    selfRepair: "Self Repair",
    phoneCall: "Phone Call",
    inspection: "Year Inspection",
    contactPerson: "UseUnit Person",
    contactTel: "Phone",
    servicePersonnel: "Service Personnel",
    hasPeople: "Has People",
    noPeople: "No People",
    recordState_0: "Alarm Reported",
    recordState_1: "Alarm Accepted",
    recordState_2: "Arrived",
    recordState_3: "Completed",
    recordState_4: "Recovery",
    recordState_5: "Two Alarm Completed",
    recordState_6: "Confirmed",
    recordState_7: "Auto Confirmed",
    recordState_8: "Cancelled",
    alarmReported: "Alarm Reported",
    notReported: "Not Reported",
    alarmed: "Alarmed",
    unAlarmed: "Not Alarmed",
    arrived: "Arrived",
    notArrived: "Not Arrived",
    finished_: "Finished",
    recovery: "Recovered",
    notFinished: "Not Finished",
    twoAlarmCompleted: "Two Alarm Completed",
    confirmed_: "Confirmed",
    notConfirmed: "Not Confirmed",
    autoConfirmed: "Auto Confirmed",
    cancelled: "Cancelled",
    received: "Received",
    finished: "Finished",
    completed: "Completed",
    confirmed: "Confirmed",
    canceled: "Canceled",
    receive: "Receive",
    finish: "Finish",
    complete: "Complete",
    confirm: "Confirm",
    cancel: "Cancel",
    baseInfo: "BaseInfo",
    alarmReportedBy: "Alarm People",
    alarmPeopleTel: "Alarm People Phone",
    emergencyRepairRecord: "Emergency Repair Record",
    system: "System",
    faultRecords: "Fault Records",
    faultNo: "Fault No.",
    faultType: "Fault Type",
    faultTime: "Fault Time",
    callTime: "Call Time",
    mainBoardTime: "Main Board Time",
    faultStorey: "Fault Storey",
    faultFloorCode: "Fault Floor Code",
    displayFaultFloor: "Display Fault Floor",
    unKnowFault: "UnKnow Fault",
    elevatorInfo: "Elevator Info",
    monitorElevator: "Monitor elevator",
    propertyInfo: "Property Info",
    revocationReason: "Revocation Reason",
    confirmPeople: "Confirm People",
    propertyName: "Property Name",
    hex: "Hex",
    workorderInfoTrack: "Workorder Info Track",
    deviceInfo: "Device Info",
    status: "Status",
    onLine: "On Line",
    offLine: "Off Line",
    createTime: "Time",
    launcher: "Launcher",
    rescueMap: "Rescue Map",
    currentLocation: "Current Location >Elevator Emergency Maintenance",
    tip: {
      stoped: "The process has been suspended and cannot be operated.",
      cantRepeat: "Cannot repeat the Service Personnel",
      want: "Do you want to ",
      mark: "?",
    },
  },
  callRecord: {
    callRecord: "Call Record",
    callTime: "Call Time",
    callDuration: "Call Duration",
    originator: "Originator",
    connector: "Connector",
    phone: "Connector Phone Number",
    setMG: "Reset",
    createTime: "Create Time",
  },
  potentialFault: {
    potentialFault: "Potential Fault of Elevator",
    potentialFaultDetails: "Potential Fault Details",
    faultOccurrenceTime: "Fault Occurrence Time",
    totalPoints: "Total Points",
    warningValue: "Warning Value",
    faultCode: "Fault Code",
    faultDesc: "Fault Desc",
    weight: "Weight",
    faultCount: "Fault Count",
    healthIndex: "Health Index",
    healthIndex_low: "low",
    healthIndex_middle: "middle",
    healthIndex_high: "high",
    potentialFaultName: "Potential Fault Name",
  },
  floorDisplay: {
    floorDisplay: "Floor Display",
    floorDisplayNo: "Floor Display No.",
    no: "No.",
    name: "Name",
    setting: "Setting",
  },
  faultTemplate: {
    startFloor: "Start Floor",
    endFloor: "End Floor",
    templateNo: "Template No.",
    isBuiltIn: "Is Built-in",
    editDefaultFaultTemplate: "Edit Chinese Default Fault Template",
    editDefaultFaultTemplateEn: "Edit English Default Fault Template",
    faultTemplate: "Fault Template",
    faultTemplateNo: "Fault Template No.",
    companyName: "Company Name",
    no: "No",
    name: "Name",
    type: "Type",
    type1: "Control System",
    type2: "Frequency Converter",
    type3: "Door Machine",
    baseInfo: "BaseInfo",
    faultCode: "Fault Code",
    faultType: "Fault Type",
    SerialNo: "No.",
    solution: "Solution",
    reason: "Reason",
    addaRow: "Add a Row",
    deleteSelectedRow: "Delete Selected Row",
    generatingGeneralFaultCode: "Generating general fault code",
    duplicateFaultCode: "There are duplicate fault codes",
    remark: "Remark",
  },
  developer: {
    mqtt: "Mqtt Note",
    openType: "Open Type",
    developer: "developer",
    no: "Code",
    name: "Name",
    appKey: "AppKey",
    appSecret: "AppSecret",
    status: "Status",
    contact: "Contact",
    mobile: "Mobile",
    address: "Address",
    auth: "Auth",
    activate: "Activate",
    close: "Close",
    callbackUrl: "Call Back Url",
    type: "Type",
    baseAlarmUrl: "Base Alarm Url",
    baseAlarmStatusUrl: "Base Alarm Status Url",
    baseFaultUrl: "Base Fault Url",
    baseEventUrl: "Base Event Url",
    baseTerminalUrl: "Base Terminal Url",
    information: "Basic Information",
    Subscription: "Subscription Service",
    subscriptionServiceList: {
      alarm: "Alarm",
      remove: "Remove Alarm",
      repeal: "Repeal Alarm",
      affair: "Affair",
      fault: "Fault",
    },
    tip: {
      no: "Code cannot be empty",
      name: "Name cannot be empty",
      mobile: "Please enter the correct phone number",
      appSecret: "The length is 8 digits and the letter combination",
      activateSucceed: "Activation successful",
      closeSucceed: "Close the success",
    },
  },
  developerDataAuthority: {
    developerDataAuthority: "Data Permission",
    useUnitNo: "UseUnit No.",
    useUnitName: "Name",
  },
  dtuTest: {
    tips: "Tips",
    code: "Code",
    add: "Add DTU",
    ps: "Notes",
    frequency: "Frequency/Time",
    startTesting: "Start testing",
    countdown: "Countdown",
    second: "Second",
    retest: "Retest",
    successCount: "Number of successes",
    failedCount: "Number of failures",
    tip: {
      message: "Each test lasts 5 minutes. Please wait patiently" + "test will be interrupted, you should restart.",
      tip1: "Please fill in the Dtu code",
      tip2: "The Dtu cannot be found. Searching again",
      tip3: "Test time can not be more than 24 hours",
      tip4: "Pass times keey null, or fill in the positive integer",
      tip5: "You cannot add multiple DTU at the same time.",
      tip6: "You can only add up to 50 DTU,Please delete before adding",
      tip7: "It has to be 11 digits，such as “20130113092”",
      tip8: "Are you sure about leaving the DTU test ?",
    },
  },
  msgPushRecord: {
    messagePush: "Message Push",
    allMsgPush: "Push messages to all users.",
    placeholder: "Please enter message content, up to 500 words",
    messageId: "Message",
    msgType: "Message Type",
    content: "Content",
    username: "User",
    createTime: "CreateTime",
    allUser: "All User",
    tip: {
      pushSuccess: "Push message success",
      pushFailure: "Push message failed",
    },
    common: "common",
    alarmBell: "alarm bell",
    workOrder: "repair Job",
    powerLoss: "main power loss",
  },
  publicNotice: {
    more: "More",
    noticeTitle: "Title",
    publishDate: "Release date",
    status: "isShow",
    name: "Editor",
    noticeDesc: "Content",
    tip: {
      Title: "The title cannot be empty",
    },
  },
  parts: {
    bindingRecord: "Binding Record",
    export: "Export List",
    no: "Number",
    name: "Name",
    partsTypeName: "PartsTypeName",
    elevatorCompanyName: "ElevatorCompanyName",
    partsSn: "partsSn",
    model: "Model",
    brand: "Brand",
    price: "Price",
    times: "Times",
    life: "Deadline",
    status: "Status",
    creatorName: "Creator",
    elevatorProductDescription: "Describe",
    hot: "IsHot",
    tip: {
      no: "The number cannot be empty",
      name: "The name cannot be empty",
      partsSn: "The length must be 34",
    },
    result: {
      band: "Binding",
      noBand: "not bound",
    },
  },
  partsType: {
    no: "Number",
    name: "Name",
    partsTypeSn: "partsTypeSn",
    desc: "Desc",
    tip: {
      no: "The number cannot be empty",
    },
  },
  uiSetting: {
    currentSetting: "Current settings",
    systemName: "Systematic name",
    logoLarge: "Logo large",
    logoSmall: "Logo Small",
    banner: "Home slideshow",
    appBanner: "App slideshow",
    companyName: "Company",
    faviconIcon: "Favicon icon",
    tip: {
      fileType: "Upload pictures can only be JPG, PNG format!",
      isLt2M: "Upload image size cannot exceed 2MB!",
      pictures: "If the number of images is exceeded, please delete and add",
    },
  },
  userThirdPart: {
    video: "Call",
    loginId: "Account number",
    userType: "User type",
    account: "HuanXin account",
    password: "PassWord",
    choose: "Choose account",
    tip: {
      account: "The HuanXin account cannot be empty",
      password: "The length is 6-20 digits and the letter combination",
    },
  },
  useUnit: {
    no: "No.",
    unitCode: "Credit Code",
    name: "Name",
    type: "Type",
    propertyComId: "Property",
    buildingCount: "Building",
    districtId: "District",
    address: "Address",
    lng: "Longitude",
    lat: "Latitude",
    person: "Person",
    contactTel: "ContactTel",
    phone: "Telephone",
    note: "Note",
    tip: {
      useUnitName: "Please enter use unit name",
      name: "The name cannot be empty",
      propertyComId: "The property com name cannot be empty",
      districtId: "The district cannot be empty",
      lng: "The longitude cannot be empty",
      lat: "The latitude cannot be empty",
    },
  },
  yearCheck: {
    labelWidth: "150px",
    yearCheckNo: "Annual Inspection No.",
    notice: "YearCheck Notice",
    expired: "Expired Without YearCheck",
    checkDate: "Check Date",
    date: "Option date",
    status: "Result",
    note: "Note",
    result: {
      pass: "Pass",
      noPass: "No pass",
    },
    tip: "Please select elevator",
  },
  monitor: {
    clearSearch: "Clear Search",
    escalatorMonitor: "escalator Monitor",
    escalatorConnect: "Connection...",
    error: "error",
    open: "on",
    close: "off",
    buildNum: "BNum",
    ladderNum: "LNum",
    runningSpeed: "running speed",
    retry: "retry",
    videoOnConnect: "Connecting, please wait...",
    videoConnectFailed: "Connection failed to see if plugins are installed...",
    a08Describe: "Monitoring data timeout",
    a10Describe: "Main board off-line",
    a11Describe: "Gateway off-line",
    a06Describe: "not found in the cache",
    a05Describe: "Number of queues",
    connectionDisconnection: "Disconnect",
    currentUseUnit: "Current UseUnit",
    elevatorMonitor: "Monitor",
    onlineStatus: "Online Status",
    online: "online",
    offline: "offline",
    districtID: "District",
    village: "Village Name",
    change: "Map Switch",
    monitoring: "Multiple Escalator Monitoring",
    monitor: "More Monitoring",
    monitorIBE: "ITE IOT",
    monitorA4Io: "ITE IOT",
    information: "Primary Information",
    liveData: "Live Data",
    transducer: "Transducer",
    controlPanel: "Control panel software version",
    unControlPanel: "Control board software is not standard edition",
    operationPanel: "Operation panel software version",
    customerNumber: "Customer customized series number",
    selection: "Motor and control mode selection",
    inverterStatus: "Inverter Status",
    setFrequency: "Set Frequency",
    OutputFrequency: "Output Frequency",
    OutputVoltage: "Output Voltage",
    OutputCurrent: "Output Current",
    DCBusVoltage: "DC Bus Voltage",
    PowerOnTimeAccumulation: "Power on time",
    RunningTime: "Running time",
    FaultCode: "Fault Code",
    workingTime: "Working Time",
    faultTime: "Fault Time",
    check: "Next Year Check",
    maintenance: "Next Maintenance Time",
    elevator: "The Elevator Profile",
    state: "Latch State",
    lock: "Lock",
    goUp: "Go Up",
    goDown: "Go Down",
    record: "Record",
    status: {
      open: "Open",
      close: "Close",
      motion: "Motion",
      parallelControl: "In Parallel/Cluster Control",
      fault: "Fault",
      service: "Service",
      overload: "Overload",
      networkControl: "Network Control",
      brakeSwitch: "Brake Switch",
      brakeContactorDetection: "Brake Contactor Detection",
      gateArea: "Gate Area",
      openDoorInPlace: "Open Door in Place",
      closeTheDoorInPlace: "Close the Door in Place",
      currentService: "Current Service Mode Unknown",
      isAnyone: "Is there Anyone in the Elevator Car",
      stopsOutside: "The Elevator Stops Outside the Unlocked Area",
      duringOperation: "Open the Door During Operation",
      rushTop: "Rush to the Top",
      squatBottom: "Squat on the Bottom",
      speeding: "Speeding",
      unexpectedMovement: "Unexpected Movement of Elevator Car",
      safetyCircuitBreak: "Safety Circuit Break",
      circuitMalfunction: "Floor Door Lock Circuit Malfunction",
      circuitFault: "Elevator Car Door Lock Circuit Fault",
      controlDevice: "Elevator Control Device Failure",
      machineFailure: "Traction Machine Failure",
      inverterFailure: "Inverter Failure",
      systemFailure: "Elevator Brake System Failure",
      shortCircuit: "Door Lock Electrical Device Short Circuit",
      levelingInductionFault: "Leveling Induction Fault",
      floorLocationLost: "Floor Location Lost",
      limiterAction: "Motor Operation Time Limiter Action",
      closeDoorFault: "Close the Door Fault",
      openDoorFault: "Open the Door Fault",
      emergencyStopFault: "Emergency Stop Fault",
      runningDistance: "Elevator running distance",
      stationLayer: "Number of door openings at the base station layer",
      numberOfDoorOpenings: "Number of door openings",
      touchpadActions: "Number of safety touchpad actions",
      curtainActions: "Number of light curtain actions",
      lighting: "Number of times of elevator car lighting",
      lightingTime: "Elevator car lighting time",
      downwardRelevelingTimes: "Downward releveling times",
      upwardRelevelingTimes: "Upward releveling times",
      runSpeed: "Run Speed",
      instructionSettings: "Instruction settings",
      IcCardAuthority: "IC card authority",
      remoteLockElevator: "Remote lock elevator",
      Open: "open",
      Close: "close",
      setting: "Setting",
      showFloor: "Show Floor",
      elevatorCarCommand: "Elevator car command",
      hallInstruction: "Outside the elevator hall instruction",
      cancel: "Cancel",
      saveSettings: "Save Settings",
      physicalFloor: "Physical Floor",
      allClosed: "All closed",
      inExecution: "In execution",
      currentSettings: "Read the current settings",
      fullyOpen: "Fully open",
      loaded: "Fully",
      safety: "Safety Circuit Break",
      fire: "Firemen",
      fireReturn: "Fire Return",
      earthquakeModel: "Earthquake Model",
      emergencyPower: "Emergency Power",
      serviceable: "Elevator Serviceable",
      mainPowerOff: "Main Power Off",
      openButton: "Open Button",
      independence: "Independence",
      Lock: "Lock",
      driver: "Driver",
      unKnow: "unKnow",
      passengerStatus: "The elevator was",
      doorCircuit: "Door Circuit Break",
    },
    parameter: "Operating Parameter",
    parameters: {
      times: "Elevator operation times",
      time: "Elevator operation time",
      wire: "The number of bending times of wire rope",
      openTimes: "Lift open and close times",
    },
    mark: {
      position: "Current Position",
    },
    fault: {
      fault1: "Normal Operation",
      fault2: "Fault Tiring",
      fault3: "Bolt Lost",
    },
    elevatorName: "Name",
    alarmType: "Type",
    continueTime: "Continue Time",
    operation: "Operation",
    elevatorStop: "Elevator Stop",
    elevatorStranded: "Elevator Stranded",
    alarmBellsAlarm: "Alarm Bells Alarm",
  },
  company: {
    tenantName: "Tenant",
    renovationCompany: "Renovation Company",
    addUser: "Add User",
    company: "Company",
    width: "200px",
    no: "No.",
    name: "Name",
    abbreviation: "Abbreviation",
    elevCount: "Elevator Count",
    userCount: "User Count",
    externalNo: "External No.",
    brand: "Brand",
    type: "Type",
    parentId: "Superior",
    person: "Person",
    phone: "Phone",
    factoryNo1: "Factory No.",
    dedicatedCode: "Dedicated Code",
    unifiedSocialCreditCode: "Unified social credit code",
    tip: {
      no: "The agency number cannot be empty",
      name: "The name cannot be empty",
    },
  },
  quantity: {
    no: "No.",
    name: "Name",
    abbreviation: "Abbreviation",
    districtId: "Region",
    address: "Address",
    tip: {
      no: "The organization number cannot be empty",
      name: "The organization name cannot be empty",
      districtId: "The administrative region cannot be empty",
      address: "The organization address cannot be empty",
    },
  },
  role: {
    role: "Role",
    labelWidth: "120px",
    nameEn: "English Name",
    auth: "Permission list",
    name: "Role name",
    isDefault: "Default role",
    orgName: "Enterprise name",
    tip: {
      name: "The role name cannot be empty",
    },
  },
  device: {
    exportExcel: "Export Excel",
    onlineRate: "Online Rate",
    labelWidth: "130px",
    sendA0: "Send A0",
    code: "Number",
    dtuNum: "DTU",
    nodeNum: "Node",
    onlineNode: "Online",
    status: "Status",
    online: "Online",
    offline: "Offline",
    port: "Port",
    video: "Video",
    softwareVersion: "Software Version",
    hardwareVersion: "Hardware Version",
    signalIntensity: "Signal Intensity",
    temperature: "Temperature",
    electricQuantity: "Electric Quantity",
    setUp: "Set Up",
    read: "Read",
    elevatorNum: "Elevator Count",
    APNName: "APN Name",
    userName: "UserName",
    password: "Password",
    all: "All",
    refresh: "Refresh",
    simCard: "SIM Card",
    record: "Record",
    upgrade: "upgrade",
    monitor: "Monitor",
    expandAll: "Expand All",
    collapseAll: "Collapse All",
    upgradeConfirm: "Confirm the upgrade?",
    iccidIsEmpty: "iccid Is Empty",
    offlineDuration: "Offline duration",
    within2Hours: "Within 2 hours",
    more2hoursLess1Day: "More than 2 hours and less than 1 day",
    moreThan1Days: "More than 1 days",
    custom: "custom",
    minute: "minute",
    hour: "hour",
    day: "day",
    illegalType: "Illegal type input",
    cannotBeGreater: "Start time cannot be greater than end time",
    pleaseEnter: "Please enter the query time",
    intercom: "Intercom Set",
    serverAddress: "Address",
    callNos: "Call Nos",
    dutyRoom: "Duty Room",
    volume: "Volume",
    inFilter: "In Filter",
    account: "SIP Account",
    readLoadingTxt: "Parameter reading, please wait patiently",
    setLoadingTxt: "Parameter writing, please wait patiently",
    readSuccess: "Read parameter success",
    readFail: "Read parameter failure",
    writeSuccess: "Set parameter success",
    writeFail: "Set parameter failure",
  },
  mb: {
    changeType: "Operational Context",
    changeTime: "Operation Time",
  },
  maintWorkOrder: {
    maintWorkOrder: "Maintenance Order",
    all: "All",
    send: "Send",
    completionSituation: "Completion Situation",
    maintPlan: "Maint Plan",
    clearSelection: "Clear Selection",
    filter: "Keyword Search",
    maintStatus: "Maint Status",
    completionStatus: "Completion Status",
    planDate: "Maint Plan Date",
    propertyCom: "Property Company",
    maintDate: "Maint Date",
    maintType: "Maint Type",
    completionTime: "Completion Time",
    maintDuration: "Maint Duration",
    normal: "Normal",
    advance: "Advance",
    overdue: "Overdue",
    invalid: "Invalid",
    inPlan: "In Plan",
    notAccepted: "Not Accepted",
    accepted: "Accepted",
    signedIn: "Signed In",
    completed: "Completed",
    creator: "Creator",
    plan: {
      elevator: "Elevator",
      selected: "Plans can be added. ",
      row: " selected",
      have: "Yes",
      no: "Nothing",
      planStartTime: "Plan Start Time",
      planEndTime: "Plan End Time",
      maintCycle: "Maint Cycle",
    },
    haveMaintPlan: "Have Maint Plan",
    orgName: "Company Name",
    productTypeName: "Elevator Product Type",
    recentNotAccept: "Last 24 Hours Not Accept",
    expired: "Expired Without Maintenance",
    selectElevator: "Select Elevator",
    checkMaintItem: "Check Maint Item",
    remark: "Remark",
    invalidReason: "Invalid Reason",
    tip: {
      getMaintTypeItemError: "Get maint type item error",
      maintTypeName: "Maint type name cannot be empty",
      elevatorProductType: "Elevator product type cannot be empty",
      maintTypeClassify: "Maint type classify cannot be empty",
      itemName: "Item name cannot be empty",
      maintContent: "Maint content cannot be empty",
      maintRequire: "Maint require cannot be empty",
      maintArea: "Maint area cannot be empty",
      getElevatorProductTypeError: "Get elevator product type error",
      timeError: "Plan start date must be greater than end date",
      timeError1: "The current date interval exceeds the maint cycle",
      planStartDate: "Plan start date cannot be empty",
      planEndDate: "Plan end date cannot be empty",
      maintCycle: "Please input a number greater than 0",
      emptyPlan: "Maintenance plan cannot be empty",
      elevatorNo: "Elevator number cannot be empty",
      maintType: "Maint type cannot be empty",
      date: "Maintenance date cannot be empty",
      invalidReason: "Invalid reason cannot be empty",
      emptyMaintType: "Please select maint type",
      confirmDelete: "Are you sure you want to delete the selected records? Deleted records cannot be recovered",
      emptyMultipleRow: "Please select at least one record",
      emptySingleRow: "Please select one record",
      confirmInvalid: "Are you sure to invalid the selected elevator maintenance plan? The invalided maintenance plan cannot be restored",
      invalidSuccess: "Invalid Success",
      invalidError: "Invalid Error",
      confirmSend: "Confirm to send the selected order?",
      sendSuccess: "Send Success",
      sendError: "Send Error",
    },
    workCondition: "Work Condition",
    maintArea: "Maint Area",
    maintContent: "Maint Content",
    condition: {
      notProcessed: "Not Processed",
      noSuchItem: "No Such Item",
      qualified: "Qualified",
      qualifiedAfterAdjustment: "Qualified After Adjustment",
      unqualified: "Unqualified",
    },
    situationDescription: "Situation Description",
    picture: "Picture",
    soundRecord: "Sound Record",
    processTime: "Process Time",
    maintenanceArea: {
      machineRoom: "Machine Room",
      bridge: "Bridge",
      layer: "Layer",
      wellRoadAndTheBottomPit: "Well Road And The Bottom Pit",
      escalator: "Escalator",
      other: "Other",
    },
    maintTypeName: "Maint Type Name",
    generatePlan: "Generate Plan",
    currentLocation: "Current Location",
    orderNum: "Order No.",
    created: "Created",
    notSend: "Not Send",
    notSignIn: "Not Sign In",
    notCompleted: "Not Completed",
    maintBaseInfo: "Maintenance Base Info",
    maintainerName1: "Maint Worker1",
    maintainerName2: "Maint Worker2",
    maintItem: "Maint Item",
    maintSetting: "Maint Setting",
    treatmentProcess: "Treatment Process",
    maintTypeClassify: "Maint Type Classify",
    isDefault: "Is Default",
    itemName: "Item Name",
    maintRequire: "Maint Require",
    confirmChoice: "Confirm Choice",
    selectMaintItem: "Select Maint Type Item",
    type: {
      halfMonth: "Half Month",
      month: "Month",
      halfYear: "Half Year",
      year: "Year",
    },
    setting: {
      autoSendDay: "Auto Send Day: ",
      maintDistance: "Maintenance Distance",
      day: "days",
      meter: "meters",
      isAutoGenerate: "Auto Generate: ",
      generateInitialTime: "Generate Initial Time: ",
      completeDate: "Complete Date",
      planDate: "Plan Date",
      maintCycleDay: "Maintenance Cycle: ",
      signInMethod: "Sign In Method: ",
      photo: "Take Photo",
      isSignInApp: "Are customers allowed to sign on the maintainer's app? : ",
      isOperationOverdue: "After the maintenance work sheet is extended, can you continue the operation? : ",
      isTwoMaintainerCheckIn: "Are both of the maintainers allowed to sign up? : ",
      isPaperMaintPhotoRequired: "Must upload photos of the papery maintenance order? : ",
      fieldWorkMethods: "Please select the following field work methods: ",
      default:
        "Default: field operations other than the sign in and finish operations, there are no restrictions on the operation, and other operations must be completed",
      custom: "Custom",
      isPhotoCheckIn: "Must sign in by taking pictures",
      isMaintainerMustSignInApp: "The maintenance personnel must sign on the App before completing the work order",
      isPhotoAbnormal: "For abnormal maintenance items, you must take pictures",
      isSoundRecordAbnormal: "For abnormal maintenance items, recordings must be made",
      isContextAbnormal: "For abnormal maintenance items, you must fill in the text description",
      isPhotoNormal: "For normal maintenance projects, you must take pictures",
      isSoundRecordNormal: "For normal maintenance projects, recordings must be made",
      isContextNormal: "For normal maintenance items, you must fill in the text description",
      sendTip: "enter a value between 0 and 7, and the 0 value is not sent automatically",
      cycleTip: "enter a value between 0 and 90",
      distanceTip: "enter a value greater than 0",
    },
    sigAndReturnVisit: "Customer Signature And Return Visit",
    evaluation: "Maintenance Quality Service Evaluation",
    dissatisfied: "Dissatisfied",
    commonly: "Commonly",
    satisfied: "Satisfied",
    verySatisfied: "Very Satisfied",
    customerSignature: "Customer Signature",
    returnRecord: "Return Record",
    uploadPicture: "Upload live photos",
    elevatorName: "Elevator Name",

    allState: "All State",
    onlyPlan: "Only Plan",
    batchDeleteMaint: "Delete Maintenance Plans In Batches",
    maintenancePolicyStatus: "Maintenance Policy Status",
    labelWidth: "180px",
    title:
      "Each elevator only needs to add a maintenance plan, and the subsequent maintenance plan will be automatically generated after the last maintenance is completed or overdue, without adding again.  \n" +
      "If the elevator has an unfinished maintenance work order/no maintenance company/no maintenance worker, the elevator will be ignored when it is created. ",
    tips: "This function will delete all maintenance work orders of the selected elevator at the same time selected state, please use with caution.  Maintenance work orders will not be generated automatically after being deleted, which is equivalent to suspending maintenance",
    batchDelete: "Batch Delete",
    actualMaintenanceTime: "Actual maintenance time",
    export: "Export",
    no: "No.",
    company: "Maint company",
    date: "Maint date",
    status: "Status",
    State: "Status Changes",
    ultraTime: "Ultra time",
    hasExtended: "Has extended",
    works: "In the works",
    execution: "In execution",
    stocks: "Off the stocks",
    confirmed: "Confirmed",
    affirm: "Confirm",
    content: "Maint content",
    userEvaluation: "User evaluation",
    property: "Property signature",
    maintenance: "Maint work signature",
    satisfaction: "Satisfaction",
    advice: "Advice",
  },
  msgSend: {
    phoneNo: "Sent phone number ",
    content: "Sent content",
    sendTime: "Send time",
  },
  userLoginLog: {
    userName: "User Name",
    name: "Name",
    startTime: "Start Time",
    endTime: "End Time",
    loginType: "Login Type",
    loginIp: "Login IP",
    loginTime: "Login Time",
    web: "Web",
    app: "APP",
    wx: "WX",
  },
  newMsgSend: {
    type: "Send Type",
    content: "Send Content",
    target: "Target User",
    referBizNo: "Refer No",
    sendTime: "Send Time",
    msgPush: "APP Message Push",
    sms: "sms",
    email: "Email",
    wxMsgPush: "Weixin Message Push",
    status: "Send Status",
    failSend: "Send Failed",
    sendSucceed: "Send Succeed",
    notSend: "Not Send",
  },
  userOperateLog: {
    userName: "User Name",
    name: "Name",
    startTime: "Start Time",
    endTime: "End Time",
    operateContent: "Operate Content",
    operateLog: "Operate Log",
    appOperateLog: "App Operate Log",
  },
  msgTemplate: {
    name: "Name",
    content: "Message Content",
    supportVars: "AvailableParameters",
  },
  userDebugInfo: {
    userDebugInfo: "User Debug Info",
    userName: "User",
    debugLevel: "Debug Level",
    passwordExpirationDate: "Password Ex-Date",
    contractNOs: "Contract NO.",
    tip: {
      placeholder: "Please separate multiple contract NO in English comma.",
      userId: "Please select the user",
      debugLevel: "Please select the debug level",
      passwordExpirationDate: "Please select Password Ex-Date",
      contractNOs: "Please enter the contract NO",
    },
  },
  sysItem: {
    name: "Type",
    value: "Value",
    status: "ON-OFF state",
    open: "ON",
    close: "OFF",
  },
  gatewayVersion: {
    gatewayVersion: "gateway Version",
    sofewareName: "Sofeware Name",
    sofewareVersionID: "Sofeware Version ID",
    sofewareVersion: "Sofeware Version",
    status: "Status",
    isFullAdapter: "Is FullAdapter",
    note: "Note",
    upload: "Upload",
    click: "Click",
    md5: "MD5",
    releaseDate: "Release Date",
    creator: "Creator",
    yes: "Yes",
    no: "No",
  },
  elevatorUser: {
    elevatorUser: "Elevator and User Binding",
    selectLift: "Select Lift",
    selectUser: "Select User",
    elevatorNotSelected: "Elevator Not Selected",
    userNotSelected: "User Not Selected",
  },
  thirdPartyPlatform: {
    name: "Name",
    note: "Note",
    set: "Set",
    companyName: "Company Name",
    account: "Account",
    password: "Password",
  },
  vibrationData: {
    idNo: "ID No.",
    elevatorName: "Elevator Name",
    createTime: "Create Time",
    runState: "Run state",
    dataDesc: "Remark",
    downLoad: "DownLoad",
    curve: "Curve",
    tab1: "DownList",
    tab2: "Operator",
    collectTime: "Collect Time",
    collectFrequency: "Collect Frequency",
    collectType: "Collect Type",
    calibration: "Calibration",
    eocdParamsSet: "Params set",
    searchEOCDData: "Search EOCD Data",
    startCollect: "Start Collect",
    endCollect: "End Collect",
    clearCollect: "Clear Record",
    cancelCollect: "Cancel Collect",
    parTotalSwitch: "EOCD Switch",
    parCollectSwitch: "EOCD Collect Switch",
    parCollectCycle: "Collect Cycle",
    parCollectFrequency: "Collect Frequency",
    acquisitionParameters: "Acquisition Parameters",
    autoUpload: "Auto Upload",
    doorCollectSwitch: "Door Collect Switch",
    doorCollectCycle: "Door Collect Cycle",
    alarmFileSwitch: "Alarm File witch",
    safetyHazardSwitch: "Safety Hazard Switch",
    safetyHazardRecord: "Safety Hazard Record",
    read: "Read",
    set: "Set",
    dtuNoOnline: "The terminal is not online and does not support this operation",
  },
  alarm: {
    id: "alarm id",
    mid: "mid",
    code: "code",
    value: "value",
    description: "description",
    picUrl: "image",
    videoUrl: "video",
    uploadTime: "time",
  },
  dwmManger: {
    no: "dwmManger no",
    elevatorNo: "dwmManger elevatorNo",
    elevatorName: "dwmManger elevatorName",
    creator: "dwmManger creator",
    createTime: "dwmManger createTime",
    status: "dwmManger status",
    noSubmit: "dwmManger noSubmit",
    submit: "dwmManger submit",
    safetyOfficerName: "dwmManger safetyOfficerName",
    tip: {
      confirmDelete: "Are you sure you want to delete the selected records? Deleted records cannot be recovered",
      handleResult: "Please enter the result of handling",
    },
    filter: "Keyword Search",
    itemType: "dwmManger itemType",
    itemName: "dwmManger itemName",
    itemValue: "dwmManger itemValue",
    handleResult: "dwmManger handleResult",
    precaution: "precaution",
    safetyOfficerOpinion: "safety officer opinion",
    safetyDirectorOpinion: "safety director opinion",
    mainProblem: "main problem",
    rectification: "rectification",
    unresolvedProblem: "unresolved problem",
    scheduleContent: "schedule content",
    safetyContent: "safety content",
    risk: "risk",
    safetyManagementEvaluation: "safety management evaluation",
    workFocus: "workFocus",
    noProblem: "no problem",
    someProblem: "some problem",
    notApplicable: "not applicable",
    attachment: "attachment",
  },
  subPlatform: {
    subPlatform: "Sub Platform",
    platformName: "Platform Name",
    shortName: "Short Name",
    baseUrl: "Base Url",
    mqtt: "MQTT",
    useState: "Use State",
    userName: "User Name",
    userAccount: "User Account",
    userContact: "User Contact"
  },
};
