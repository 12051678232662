export default {
  lang: "zh",
  login: {
    login: "登录",
    logout: "注销",
    welcome: "欢迎",
    account: "账号",
    password: "密码",
    forgotPassword: "找回密码",
    app: "下载APP",
    qrCodeApp: "扫一扫下载电梯云APP",
    chromeDown: "立即下载",
    chromeLogin: "建议使用chrome浏览器登录",
    phone: "手机号",
    verify: "验证码",
    newPassword: "新密码",
    submit: "提交",
    sendMSG: "发送短信",
    resend: "重新发送",
    tips: {
      account: "请输入账号",
      password: "请输入密码",
      notNull: "不能为空",
      logout: "确定退出登录吗？",
    },
  },
  common: {
    watch: "观看",
    add: "新增",
    search: "查询",
    clear: "清空",
    advanceSearch: "高级查询",
    pleaseEnter: "请输入",
    pleaseSelect: "请选择",
    pleasePartsSn: "必须34个字符",
    chooseAnArea: "未选择",
    select: "选择",
    operation: "操作",
    confirm: "确定",
    cancel: "取消",
    delete: "删除",
    remove: "移除",
    detail: "详情",
    isDelete: "确定删除吗",
    setting: "设置",
    setBlack: "确定设置选择的网关设备为黑名单吗?",
    cancelBlack: "确定选择的网关设备取消黑名单吗?",
    settingError: "设置失败",
    isAdd: "确定添加吗",
    edit: "编辑",
    alarm: "报警记录",
    save: "保存",
    yes: "是",
    no: "否",
    startDate: "开始日期",
    endDate: "结束日期",
    beginTime: "开始时间",
    endTime: "结束时间",
    to: "至",
    confirmTip: "确定删除此行吗",
    filter: "输入关键字过滤",
    tips: "提示",
    more: "展开",
    less: "收缩",
    set: "设置",
    tip: {
      getDataError: "数据获取失败",
      notNull: "不能为空",
      needNum: "请输入大于0的数字",
      needNum1: "请输入 >=0 的数字",
      saveSuccess: "保存成功",
      optional: "出厂编号与电梯注册代码至少填一项！",
      completeFloorInformation: "请完善楼层信息",
      saveError: "保存失败",
      deleteSuccess: "删除成功",
      settingSuccess: "设置成功",
      deleteError: "删除失败",
      addSuccess: "添加成功",
      addError: "添加失败",
      submitError: "请修改后提交",
      operationSuccess: "操作成功",
      operationFailure: "操作失败",
    },
  },
  headerBar: {
    app: "下载APP",
    unavailable: "视频监控未开通",
    connect: "正在连接...",
    nonsupport: "当前环境不支持",
    connectionFailed: "视频监控连接失败",
    available: "视频监控正常",
    newConnection: "视频监控有新呼叫",
    landed: "其他地方登录",
    setting: "账户设置",
    logout: "注销登录",
    uiSetting: "界面设置",
  },
  home: {
    lifts: "入网电梯数量",
    users: "用户数量",
    liftsLocation: "电梯分布",
  },
  route: {
    createCompany: "制造单位",
    maintCompany: "维保单位",
    propertyCompany: "物业单位",
    installCompany: "安装单位",
    changeCompany: "改造单位",
    useUnitCom: "使用单位",
    camera: "智联摄像",
    home: "首页",
    account: "账号管理",
    notice: "公告信息",
    uiSetting: "界面设置",
    noticeDetail: "公告详情",
    elevator: "电梯档案",
    peopleCounting: "人数统计",
    elevatorInfo: "电梯信息",
    device: "设备管理",
    danger: "健康指数",
    elevatorUser: "电梯用户",
    monitor: "电梯监控",
    useUnitMonitor: "小区监控",
    map: "GIS地图",
    singleMonitor: "单梯监控",
    videoCall: "视频通话",
    emergencyRepair: "电梯急修",
    repairJob: "急修工单",
    annualInspection: "电梯年检",
    annualInspectionRecord: "年检记录",
    userRole: "用户角色",
    user: "用户管理",
    role: "角色管理",
    baseInfo: "基础信息",
    useUnit: "使用单位",
    gatewayVersion: "网关程序版本",
    thirdPartyPlatform: "第三方平台",
    messagePush: "消息推送",
    parts: "配件管理",
    partsType: "配件类型",
    company: "公司管理",
    qualitySupervision: "质检机构",
    videoUser: "视频用户",
    dtuTest: "网关测试",
    floorDisplay: "楼层对照",
    faultTemplate: "故障模板",
    developerPermission: "开发者",
    userDebugInfo: "调试人员",
    potentialFaultsetting: "安全隐患设置",
    deviceChangeRecord: "事件记录(废弃)",
    mb: "事件记录",
    faultRecords: "故障记录",
    eventForWarehouse: "事件记录（仓储系统）",
    integration: "对接设置",
    elevatorMaintenance: "电梯维保",
    maintenanceWorkOrder: "保养工单",
    safeCheckOrder: "抽查工单",
    maintenanceSetting: "维保设置",
    upkeep: "维保管理",
    accessPlatform: "接入平台",
    msgSend: "短信发送记录",
    userTakeRecord: "呼梯记录",
    msgTemplate: "短信配置",
    sysItem: "平台设置",
    system: "系统管理",
    event: "事件管理",
    ztVersion: "私有平台",
    equip: "网关设备",
    shanghai: "上海对接",
    wuxi: "无锡对接",
    chongqing: "重庆对接",
    shenyang: "沈阳对接",
    jiaxing: "嘉兴对接",
    guangxi: "广西对接",
    zhejiang: "浙江对接",
    fujian: "福建对接",
    nanchang: "南昌对接",
    shaoxing: "绍兴对接",
    hangzhou: "杭州对接",
    ningbo: "宁波对接",
    liaoning: "辽宁对接",
    huzhou: "湖州对接",
    elevatorNetApply: "入网申请",
    newMsgSend: "消息记录",
    deviceGateway: "网关设备",
    tenant: "租户管理",
    Operations: "运营管理",
    kanban: "看板展示",
    board: "数据看板",
    userLoginLog: "登录日志",
    userOperateLog: "操作日志",
    elevatorSetting: "档案设置",
    systemSetting: "系统设置",
    contract: "合同管理",
    programCard: "节目单",
    tpLinkVideo: "TP摄像头",
    vmVideo: "智能摄像",
    eocdPotential: "安全隐患",
    dayWeekMonthManger: "日周月监管",
    dayControl: "日管控",
    weekCheck: "周排查",
    vxeTableTest: "VxeTable测试",
    monthDispatch: "月调度",
    checkTemplates: "排查模板",
    brand: "电梯品牌",
  },
  elevatorNetApply: {
    useUnitDistrict: "使用单位地区",
    companyInfo: "企业信息",
    useUnit: "使用单位",
    useUnitAddress: "使用单位详细地址",
    manufactured: "制造企业",
    creditCode: "社会信用代码",
    maintComName: "维保企业",
    maintEmpName: "维保人员",
    maintEmpPhone: "维保人员手机",
    propertyCom: "物业公司",
    elevatorInfo: "电梯信息",
    sim: "SIM卡号",
    dtuCode: "终端编号",
    nodeCode: "节点编号",
    elevatorType: "梯种",
    brand: "品牌",
    elevatorModel: "规格型号",
    load: "载重",
    speed: "额度速度(m/s)",
    dtuModel: "终端型号",
    controlModel: "控制方式",
    regCode: "注册代码",
    factoryNumber: "出厂编号",
    elevatorName: "电梯名称",
    exFactoryDate: "出厂日期",
    installationDate: "安装日期",
    videoChannelId: "轿厢摄像头ID",
    addFloorMappings: "请添加楼层信息",
  },
  user: {
    user: "用户",
    username: "账号",
    password: "密码",
    type: "用户类型",
    messageSendType: "通知发送方式",
    name: "姓名",
    phone: "手机号",
    repair: "急修短信",
    maintenance: "维保短信",
    company: "企业名称",
    lastLoginTime: "最后登陆时间",
    quality: "质监局",
    mail: "邮箱",
    maintainer: "维保工",
    messageControl: "短信控制",
    changePwdState: "可改密码",
    resetPassword: "重置密码",
    unselectedRole: "未选角色",
    selectedRole: "已选角色",
    reset: "重置",
    is: "的",
    types: {
      0: "星辰用户",
      1: "企业管理员",
      2: "企业普通用户",
      9: "普通用户",
    },
    tip: {
      mail: "请输入正确的邮箱",
      phone: "请输入正确的手机号",
      password: "必须同时包含字母和数字，长度在8-30之间",
      tips: "密码为A123456b",
      successful: "重置密码成功",
      failed: "重置密码失败",
    },
    account: {
      baseInfo: "基本信息",
      oldPassword: "旧密码",
      newPassword: "新密码",
      confirm: "确认新密码",
      tip: {
        confirm: "两次输入密码不一致",
      },
    },
  },
  elevator: {
    elevator: "电梯",
    addElevator: "添加电梯",
    no: "电梯编号",
    address: "详细地址",
    person: "联系人",
    elevatorType: "梯种",
    registerCode: "设备代码",
    name: "电梯名称",
    brand: "品牌",
    model: "规格型号",
    maintWorker: "维保工",
    maintType: "维保类型",
    safetyOfficer: "安全员",
    safetyDirector: "安全总监",
    safetyMainer: "主要负责人",
    dtuCode: "终端编号",
    nodeCode: "节点编号",
    dtuModel: "终端型号",
    simCardNo: "SIM卡号",
    iccid: "ICCID",
    exFactoryNo: "出厂编号",
    controlCabinetLeaveNo: "控制柜出厂编号",
    exFactoryNoWx: "无锡出厂编号",
    outerDeviceWx: "无锡外设编号",
    statusWx: "是否接入",
    maintPeriod: "维保周期(天)",
    whereUsed: "使用场所",
    faultTemplate: "故障模板",
    deviceGateway: "网关设备",
    notInstalled: "未安装",
    installed: "已安装",
    installed_plug: "已安装(插件形式)",
    installed_link: "已安装(链接形式)",
    videoChannelId: "轿厢摄像头ID",
    videoUrl: "轿厢视频链接",
    motorRoomVideoUrl: "轿厢视频FLV",
    video: "视频",
    joinStatus: "入网状态",
    isParallel: "并联/群控",
    openStatus: "是否公开",
    open: "公开",
    notOpen: "不公开",
    halfOpen: "半公开",
    onlineStatus: "在线状态",
    accessPlatform: "对接平台",
    activated: "已认证",
    notActivated: "未认证",
    useStatus: "使用状态",
    useUnit: "使用单位",
    property: "所属物业",
    maintenanced: "维保企业",
    propertyCom: "物业公司",
    qualityOrgan: "质检机构",
    manufactured: "制造企业",
    installationEnterprise: "安装企业",
    reconstructed: "改造企业",
    locCode: "位置码",
    exFactoryDate: "出厂日期",
    installationDate: "安装日期",
    joinDate: "入网日期",
    serviceDate: "使用日期",
    test: "调试",
    check: "检测",
    floors: "层数",
    ratedSpeedOfEscalator: "自动扶梯额定速度(单位：m/s)",
    riseOfEscalator: "自动扶梯提升高度(单位：m)",
    angleOfEscalator: "自动扶梯倾斜角度(单位：度)",
    widthOfEscalator: "自动扶梯名义宽度(单位：m)",
    ratedSpeedOfMovingWalk: "自动人行道额定速度(单位：m/s)",
    lengthOfMovingWalk: "自动人行道使用区段长度(单位：m)",
    angleOfMovingWalk: "自动人行道倾斜角度(单位：度)",
    widthOfMovingWalk: "自动人行道名义宽度(单位：m)",
    highestFloorOfElevator: "电梯物层楼数(单位：层)",
    ratedSpeedOfElevator: "额定速度(m/s)",
    ratedSpeedOfElevatorRule: "额定速度",
    ratedLoadOfElevator: "额定载重量(kg)",
    floorDisplayTemplate: "楼层对照",
    actualFloor: "实际楼层",
    displayedFloor: "显示楼层",
    stopStatus: "当前楼层是否停靠",
    currentFloorCode: "状态楼层代码",
    faultFloorCode: "故障楼层代码",
    addFloorDisPlay: "添加楼层对照",
    longitude: "经度",
    latitude: "纬度",
    joined: "已入网",
    notJoined: "未入网",
    useStatus_1: "在用",
    useStatus_2: "报废",
    useStatus_3: "停用",
    useStatus_4: "注销",
    maintenanceBySelf: "自保",
    maintenanceByOthers: "他保",
    baseInfo: "基本信息",
    production: "生产",
    using: "使用",
    maintenance: "维保",
    system: "系统",
    fault: "故障",
    enterprise: "企业信息",
    parameters: "电梯参数",
    extensionParameters: "扩展参数",
    iot: "物联网",
    modifyLocation: "修改位置",
    elevatorParts: {
      elevatorParts: "电梯部件",
      parts: "配件",
      add: "添加",
      category: "类别",
      number: "编号",
      name: "名称",
      serialNumber: "序列号",
      floor: "楼层",
      status: "状态",
      activated: "已认证",
      notActivated: "未认证",
      remove: "移除",
      activateTime: "激活时间",
      activator: "操作者",
      createTime: "添加时间",
      replace: "更换",
      replacementRecord: "更换记录",
      oldParts: "旧配件",
      newParts: "新配件",
      replacementTime: "更换时间",
    },
    generalParameters: "通用参数",
    floorDisplay: "楼层对照",
    alarmParameters: "报警参数",
    location: "电梯位置",
    record: "电梯记录",
    emergencyRecord: "急修记录",
    maintenanceRecord: "维保记录",
    yearCheckRecord: "年检记录",
    faultRecord: "故障记录",
    potentialFault: "安全隐患",
    device: "组网设备",
    runTimes: "运行次数",
    gradeAlarming: "分级报警",
    maintComSelectTip: "请先选择维保企业",
    propertyComSelectTip: "请先选择物业企业",
    elevatorDebuggingPlatform: "电梯调试平台",
    elevatorCheck: "电梯检测",
    lock: "锁梯",
    unlock: "取消锁梯",
    Lock: "锁定",
    Unlock: "解锁",
    closeAuth: "关闭权限",
    openAuth: "开放权限",
    activationBinding: "激活绑定",
    AllBoards: "系统内所有板子",
    elseBoards: "其他（扩展板等）",
    simCardBinding: "Sim卡绑定",
    groupControlBoard: "群控板（并联）",
    frequencyConverter: "变频器",
    carBoard: "轿厢板",
    sedanRoof: "轿顶板",
    callBoard: "外呼板",
    changeProbationPeriod: "更改试用期",
    escalatorLock: "扶梯锁梯",
    escalatorUp: "扶梯上行",
    escalatorDown: "扶梯下行",
    day: "天",
    hour: "小时",
    openDebugAuth: "远程开放调试权限",
    grade: "等级",
    time: "时间",
    stopOrStart: "停复梯",
    lockOrUnlock: "远程解锁合同号",
    contractNo: "合同号",
    refresh: "刷新",
    operationRecord: "操作记录",
    type: "类型",
    order: "命令",
    status: "状态",
    operator: "操作人",
    executing: "进行中",
    success: "成功",
    error: "失败",
    overTime: "超时",
    copy: "复制",
    batchAdding: "批量新增电梯",
    addNumber: "添加数量",
    batchModification: "批量修改",
    exportExcel: "导出",
    importSimInfo: "导入Sim卡信息",
    tip: {
      maintPeriod: "维保周期必须大于0",
      operateSuccess: "执行成功",
      operateError: "执行失败",
      atLeastOne: "请至少选择一项",
      daysAndHours: "请正确填写天数和小时",
      gradeAndTime: "请正确填写等级和时间",
      chooseFirst: "请先选择",
      want: "确定执行",
      contractNo: "合同号不能为空",
      batchAddition: "批量添加不需填写注册代码",
      needRegNO: "不能为空，没有请填出厂编号",
    },
  },
  workOrder: {
    workOrder: "工单",
    information: "工单信息",
    workOrderNo: "工单编号",
    workOrderStatus: "工单状态",
    alarmType: "报警类型",
    alarmType_0: "故障停梯",
    alarmType_1: "电梯困人",
    alarmType_2: "警铃报警",
    alarmType_3: "严重故障",
    alarmTime: "报警时间",
    deviceCode: "终端编号",
    acceptor: "受理人",
    repairPeople: "接警人",
    repairMethod: "报修方式",
    selfRepair: "自动报警",
    phoneCall: "电话报修",
    inspection: "年检产生",
    contactPerson: "联系人",
    contactTel: "联系电话",
    servicePersonnel: "服务人员",
    hasPeople: "有人",
    noPeople: "无人",
    recordState_0: "报警",
    recordState_1: "接警",
    recordState_2: "到场",
    recordState_3: "完工",
    recordState_4: "恢复",
    recordState_5: "二次报警完工",
    recordState_6: "确认",
    recordState_7: "自动确认",
    recordState_8: "撤销",
    received: "接警",
    finished: "已结",
    completed: "完工",
    confirmed: "确认",
    canceled: "撤销",
    receive: "接警",
    finish: "已结",
    complete: "完工",
    confirm: "确认",
    cancel: "撤销",
    alarmReported: "已报警",
    notReported: "未报警",
    alarmed: "已接警",
    unAlarmed: "未接警",
    arrived: "已到场",
    notArrived: "未到场",
    finished_: "已完工",
    recovery: "已恢复",
    notFinished: "未完工",
    twoAlarmCompleted: "二次报警完工",
    confirmed_: "已确认",
    notConfirmed: "自动确认",
    autoConfirmed: "未确认",
    cancelled: "已撤销",
    baseInfo: "基本信息",
    alarmReportedBy: "报警人",
    emergencyRepairRecord: "急修记录",
    system: "系统",
    faultRecords: "故障记录",
    faultNo: "故障码",
    faultType: "故障分类",
    faultTime: "故障时间",
    mainBoardTime: "主板时间",
    faultStorey: "故障楼层",
    faultFloorCode: "故障楼层代码",
    displayFaultFloor: "显示楼层",
    unKnowFault: "未知故障",
    elevatorInfo: "电梯信息",
    monitorElevator: "监控电梯",
    propertyInfo: "物业信息",
    revocationReason: "撤销原因",
    confirmPeople: "确认人",
    propertyName: "物业名称",
    hex: "十六进制",
    workorderInfoTrack: "工单信息跟踪",
    deviceInfo: "设备记录",
    status: "状态",
    onLine: "在线",
    offLine: "离线",
    createTime: "时间",
    launcher: "发射装置",
    rescueMap: "救援地图",
    currentLocation: "当前位置 >电梯急修",
    tip: {
      stoped: "此工单流程已暂停，不能操作",
      cantRepeat: "不能重复添加急修人员",
      want: "确定",
      mark: "吗？",
    },
  },
  potentialFault: {
    potentialFault: "电梯安全隐患",
    potentialFaultDetails: "安全隐患详情",
    faultOccurrenceTime: "故障发生时间",
    totalPoints: "总分",
    warningValue: "警戒值",
    faultCode: "故障代码",
    faultDesc: "故障描述",
    weight: "权重",
    faultCount: "故障次数",
    healthIndex: "健康指数",
    healthIndex_low: "低",
    healthIndex_middle: "中",
    healthIndex_high: "高",
    potentialFaultName: "隐患类型",
  },
  floorDisplay: {
    floorDisplay: "楼层对照",
    floorDisplayNo: "楼层模板编号",
    no: "编号",
    name: "名称",
    setting: "设置",
  },
  faultTemplate: {
    isBuiltIn: "是否内置",
    editDefaultFaultTemplate: "编辑中文默认故障模板",
    editDefaultFaultTemplateEn: "编辑英文默认故障模板",
    faultTemplate: "故障模板",
    faultTemplateNo: "故障模板编号",
    no: "编号",
    name: "名称",
    type: "类型",
    type1: "控制系统",
    type2: "变频器",
    baseInfo: "基本信息",
    faultCode: "故障代码",
    faultType: "故障分类",
    SerialNo: "序号",
    solution: "解决方案",
    reason: "故障原因",
    addaRow: "添加一行",
    deleteSelectedRow: "删除选中行",
    generatingGeneralFaultCode: "生成通用故障代码",
    duplicateFaultCode: "存在重复的故障代码",
    remark: "备注",
  },
  developer: {
    mqtt: "mqtt标识",
    openType: "请求方式",
    developer: "开发者",
    no: "代码",
    name: "平台名称",
    appKey: "AppKey",
    appSecret: "AppSecret",
    contact: "联系人",
    mobile: "电话",
    address: "地址",
    status: "状态",
    auth: "权限",
    activate: "激活",
    close: "关闭",
    baseUrl: "接口基地址",
    type: "类型",
    baseAlarmUrl: "报警推送接口基地址",
    baseAlarmStatusUrl: "报警状态推送接口基地址",
    baseFaultUrl: "故障推送接口基地址",
    baseEventUrl: "事件推送接口基地址",
    baseTerminalUrl: "物联网设备状态推送基地址",
    information: "基本信息",
    Subscription: "订阅信息",
    subscriptionServiceList: {
      alarm: "报警",
      remove: "消除报警",
      repeal: "撤销报警",
      affair: "事件",
      fault: "故障",
    },
    tip: {
      no: "代码不能为空",
      name: "名称不能为空",
      mobile: "6~请输入正确的手机号",
      appSecret: "AppSecret长度为8个数字和字母组合",
      activateSucceed: "激活成功",
      closeSucceed: "关闭成功",
    },
  },
  developerDataAuthority: {
    developerDataAuthority: "数据权限",
    useUnitNo: "使用单位编号",
    useUnitName: "名称",
  },
  dtuTest: {
    tips: "提示",
    code: "终端编号",
    ps: "备注",
    add: "增加DTU",
    frequency: "通过基准(次)/测试时间(分钟)",
    startTesting: "开始测试",
    countdown: "测试倒计时",
    second: "秒",
    retest: "重新测试",
    save: "保存",
    successCount: "成功次数",
    failedCount: "失败次数",
    tip: {
      message: "每次测试时间为5分钟，请耐心等待" + " 测试中请勿刷新或者关闭页面，否则测试将断，需重新开始。",
      tip1: "请填写终端编号",
      tip2: "尚未找到该Dtu，正在重新查找",
      tip3: "测试时间不能大于24小时",
      tip4: "通过基准不填，或者填入正整数",
      tip5: "不能同时添加多条DTU",
      tip6: "最多只能添加20台DTU,请删除后再添加",
      tip7: "DTU必须是11位数字，如“20130113092”",
      tip8: "确定离开DTU测试吗?",
    },
  },
  msgPushRecord: {
    messagePush: "消息推送",
    allMsgPush: "向所有用户推送消息",
    placeholder: "请输入消息内容,最多500字",
    messageId: "极光推送MessageID",
    msgType: "消息类型",
    content: "消息内容",
    username: "目标用户",
    createTime: "创建时间",
    allUser: "所有用户",
    tip: {
      pushSuccess: "推送消息成功",
      pushFailure: "推送消息失败",
    },
    common: "通用消息",
    alarmBell: "警铃报警",
    workOrder: "急修工单",
    powerLoss: "电源系统异常（停电）",
  },
  publicNotice: {
    more: "查看更多",
    noticeTitle: "标题",
    publishDate: "发布日期",
    status: "主页显示",
    name: "编辑人",
    noticeDesc: "内容",
    tip: {
      Title: "标题不能为空",
    },
  },
  parts: {
    no: "编号",
    name: "名称",
    partsTypeName: "配件类型",
    elevatorCompanyName: "整梯厂",
    partsSn: "配件Id",
    model: "型号",
    brand: "品牌",
    price: "价格",
    times: "使用次数",
    life: "期限",
    status: "状态",
    creatorName: "创建者",
    elevatorProductDescription: "备注",
    hot: "是否热门",
    tip: {
      no: "编号不能为空",
      name: "名称不能为空",
      partsSn: "配件Id必须为34个字符",
    },
    result: {
      band: "已绑定",
      noBand: "未绑定",
    },
  },
  partsType: {
    no: "编号",
    name: "名称",
    partsTypeSn: "类型序列号",
    desc: "配件描述",
    tip: {
      no: "编号不能为空",
    },
  },
  uiSetting: {
    currentSetting: "当前设置",
    systemName: "系统名称",
    logoLarge: "大Logo",
    logoSmall: "小Logo",
    banner: "首页轮播图",
    appBanner: "app轮播图",
    companyName: "公司",
    faviconIcon: "标题栏图标",
    tip: {
      fileType: "上传图片只能是 jpg、png格式!",
      isLt2M: "上传图片大小不能超过 2MB!",
      pictures: "超出图片数量，请删除后再添加",
    },
  },
  userThirdPart: {
    video: "呼叫",
    loginId: "账号",
    userType: "用户类型",
    account: "环信账号",
    password: "密码",
    choose: "选择账号",
    tip: {
      account: "环信账号不能为空",
      password: "密码长度为6到20个字母和数字的组合",
    },
  },
  useUnit: {
    no: "编号",
    name: "名称",
    unitCode: "信用代码",
    type: "类型",
    propertyComId: "所属物业",
    buildingCount: "楼数量",
    districtId: "地区",
    address: "详细地址",
    lng: "经度",
    lat: "纬度",
    person: "联系人",
    contactTel: "手机号码",
    note: "备注",
    tip: {
      useUnitName: "请输使用单位名称",
      name: "名称不能为空",
      propertyComId: "所属物业不能为空",
      districtId: "地区不能为空",
      lng: "经度不能为空",
      lat: "纬度不能为空",
    },
  },
  yearCheck: {
    all: "全部",
    yearCheckNo: "年检编号",
    date: "选择日期",
    checkDate: "检验日期",
    status: "检验结果",
    note: "备注",
    result: {
      pass: "通过",
      noPass: "不通过",
    },
    tip: "请选择电梯",
  },
  monitor: {
    escalatorMonitor: "扶梯监控",
    escalatorConnect: "连接中...",
    error: "出错",
    open: "展开",
    close: "收起",
    buildNum: "楼号",
    ladderNum: "梯号",
    runningSpeed: "运行速度",
    retry: "重试",
    videoOnConnect: "正在连接，请稍后...",
    videoConnectFailed: "连接失败，查看是否安装插件...",
    a08Describe: "监控数据超时",
    a10Describe: "主板离线",
    a11Describe: "网关离线",
    a06Describe: "缓存中未找到该电梯",
    a05Describe: "排队数量",
    connectionDisconnection: "连接断开",
    currentUseUnit: "当前小区",
    elevatorMonitor: "监控",
    onlineStatus: "在线状态",
    online: "在线",
    offline: "离线",
    districtID: "地区",
    village: "Village name",
    change: "切换地图",
    monitoring: "多梯监控",
    monitor: "更多监控",
    monitorIBE: "ITE IOT",
    monitorA4Io: "输入输出点",
    information: "主要信息",
    liveData: "变频器实时数据",
    check: "下次年检时间",
    maintenance: "下次维保时间",
    // inputVoltage: "输入电压（单位：v）",
    // transducerAccelerator: "变频器加速器（单位：m/s）",
    // ratedCurrent: "额定电流（单位：mA）",
    // radiatorTemperature: "散热器温度（单位：°）",
    // DCLink: "DC Link 电压（单位：v）",
    // ESCount: "E/S 启动次数",
    // ESTime: "E/S 运行时间",
    transducer: "变频器系列",
    controlPanel: "控制板软件版本",
    unControlPanel: "控制板软件非标版本",
    operationPanel: "操作面板软件版本",
    customerNumber: "客户定制系列号",
    selection: "电机与控制方式选择",
    inverterStatus: "变频器状态",
    setFrequency: "设定频率",
    OutputFrequency: "输出频率",
    OutputVoltage: "输出电压",
    OutputCurrent: "输出电流",
    DCBusVoltage: "直流母线电压",
    PowerOnTimeAccumulation: "通电时间累计",
    RunningTime: "运行时间累计",
    FaultCode: "变频器故障代码",
    workingTime: "扶梯运行时间",
    faultTime: "故障发生时间",
    elevator: "电梯概况",
    state: "门锁状态",
    lock: "锁  梯",
    goUp: "上  行",
    goDown: "下  行",
    record: "操作记录",
    status: {
      open: "开 门",
      close: "关 门",
      motion: "自动",
      parallelControl: "并联/群控",
      fault: "故障",
      service: "检修",
      overload: "超载",
      loaded: "满载",
      safety: "安全回路断",
      fire: "消防员",
      fireReturn: "消防返回",
      earthquakeModel: "地震模式",
      emergencyPower: "应急电源",
      serviceable: "电梯可服务",
      mainPowerOff: "电源系统异常（停电）",
      openButton: "开门按钮",
      independence: "独立",
      Lock: "锁梯",
      driver: "司机",
      unKnow: "未知",
      passengerStatus: "轿厢有人",
    },
    parameter: "运行参数",
    parameters: {
      times: "电梯运行次数",
      time: "电梯运行时间",
      wire: "钢丝绳运行弯折次数",
      openTimes: "电梯开关门次数",
    },
    mark: {
      position: "当前位置",
    },
    fault: {
      fault1: "正常运行",
      fault2: "故障困人",
      fault3: "断线失联",
    },
    elevatorName: "电梯名称",
    alarmType: "报警类型",
    continueTime: "持续时间",
    operation: "操作",
    elevatorStop: "故障停梯",
    elevatorStranded: "电梯困人",
    alarmBellsAlarm: "警铃报警",
  },
  company: {
    company: "公司",
    no: "企业编号",
    name: "企业名称",
    abbreviation: "企业简称",
    elevCount: "电梯数量",
    userCount: "用户数量",
    externalNo: "外部编号",
    brand: "品牌",
    type: "企业类型",
    parentId: "上级企业",
    person: "联系人",
    phone: "联系电话",
    factoryNo1: "厂家编号",
    dedicatedCode: "企业专用码",
    unifiedSocialCreditCode: "统一社会信用代码",
    tip: {
      no: "机构编号不能为空",
      name: "名称不能为空",
    },
  },
  quantity: {
    no: "机构编号",
    name: "机构名称",
    abbreviation: "机构简称",
    districtId: "行政区域",
    address: "机构地址",
    tip: {
      no: "机构编号不能为空",
      name: "名称不能为空",
      districtId: "行政区域不能为空",
      address: "机构地址不能为空",
    },
  },
  role: {
    role: "角色",
    auth: "权限列表",
    name: "中文名称",
    isDefault: "默认角色",
    orgName: "企业名称",
    tip: {
      name: "角色名称不能为空",
    },
  },
  device: {
    code: "编号",
    dtuNum: "DTU数量",
    nodeNum: "节点数量",
    onlineNode: "在线节点",
    status: "状态",
    online: "在线",
    offline: "离线",
    port: "端口",
    video: "视频",
    softwareVersion: "软件版本",
    hardwareVersion: "硬件版本",
    signalIntensity: "信号",
    temperature: "温度",
    electricQuantity: "电量",
    setUp: "设置",
    read: "读取",
    APNName: "APN名称",
    userName: "用户名",
    password: "密码",
    all: "全部",
    refresh: "刷新",
    simCard: "流量卡",
    record: "在线记录",
    upgrade: "升级",
    monitor: "监控",
    expandAll: "全部展开",
    collapseAll: "全部折叠",
    upgradeConfirm: "确认执行升级吗？",
    iccidIsEmpty: "iccid为空",
    offlineDuration: "离线持续时间",
    within2Hours: "2小时以内",
    more2hoursLess1Day: "超过2小时且未超过1天",
    moreThan1Days: "超过1天",
    custom: "自定义",
    minute: "分钟",
    hour: "小时",
    day: "天",
    illegalType: "输入不合法",
    cannotBeGreater: "开始时间不能大于等于结束时间",
    pleaseEnter: "请输入查询时间",
    intercom: "对讲设置",
    serverAddress: "服务器地址",
    callNos: "呼叫号码",
    dutyRoom: "值班室",
    volume: "音量",
    inFilter: "对讲过滤",
    account: "SIP账号",
    readLoadingTxt: "参数读取中,请耐心等待",
    setLoadingTxt: "参数写入中,请耐心等待",
    readSuccess: "参数读取成功",
    readFail: "参数读取失败",
    writeSuccess: "参数设置成功",
    writeFail: "参数设置失败",
  },
  mb: {
    changeType: "操作内容",
    changeTime: "操作时间",
  },
  maintWorkOrder: {
    no: "工单编号",
    company: "维保公司",
    date: "计划维保日期",
    status: "状态",
    State: "状态改变",
    ultraTime: "超期",
    hasExtended: "已超期",
    works: "计划中",
    execution: "执行中",
    stocks: "已完成",
    confirmed: "已确认",
    affirm: "确认",
    content: "维保内容",
    userEvaluation: "用户评价",
    property: "物业签名",
    maintenance: "维保工签名",
    satisfaction: "满意度",
    advice: "意见",
  },
  msgSend: {
    phoneNo: "发送手机号",
    content: "发送内容",
    sendTime: "发送时间",
  },
  msgTemplate: {
    name: "名称",
    content: "消息内容",
    supportVars: "可用参数",
  },
  userDebugInfo: {
    userDebugInfo: "调试人员",
    userName: "用户",
    debugLevel: "调试级别",
    passwordExpirationDate: "调试密码到期时间",
    contractNOs: "合同号",
    tip: {
      placeholder: "多个合同号请用英文逗号隔开 ",
      userId: "请选择用户",
      debugLevel: "请选择调试级别",
      passwordExpirationDate: "请选择密码到期日",
      contractNOs: "请输入合同号",
    },
  },
  sysItem: {
    name: "类型",
    value: "值",
    status: "开关状态",
    open: "ON",
    close: "OFF",
  },
  gatewayVersion: {
    gatewayVersion: "网关程序版本",
    sofewareName: "软件名称",
    sofewareVersionID: "软件版本标识",
    sofewareVersion: "软件版本",
    status: "状态",
    isFullAdapter: "是否设备全适配",
    note: "版本描述信息",
    upload: "上传软件",
    click: "点击上传",
    md5: "软件MD5",
    releaseDate: "发布日期",
    creator: "创建人",
    yes: "是",
    no: "否",
  },
  elevatorUser: {
    elevatorUser: "电梯用户关系维护",
    selectLift: "选择电梯",
    selectUser: "选择用户",
    elevatorNotSelected: "未选择电梯",
    userNotSelected: "未选择用户",
  },
  thirdPartyPlatform: {
    name: "名称",
    note: "描述",
    set: "设置",
    companyName: "企业名",
    account: "账号",
    password: "密码",
  },
  vibrationData: {
    idNo: "ID号",
    elevatorName: "电梯名称",
    createTime: "创建时间",
    runState: "运行状态",
    dataDesc: "备注",
    downLoad: "下载",
    curve: "曲线",
    tab1: "下载列表",
    tab2: "操作器",
    collectTime: "采样时间",
    collectFrequency: "采样频率",
    collectType: "采集类型",
    calibration: "校准",
    eocdParamsSet: "参数设置",
    searchEOCDData: "查询EOCD数据",
    startCollect: "手动开始采集",
    endCollect: "手动停止采集",
    clearCollect: "清空采集记录",
    cancelCollect: "撤销采集",
    parTotalSwitch: "轿顶EOCD总开关",
    parCollectSwitch: "轿顶EOCD自动采集开关",
    parCollectCycle: "自动采集周期",
    parCollectFrequency: "自动采集频率",
    acquisitionParameters: "采集参数",
    autoUpload: "自动上传",
    doorCollectSwitch: "开关门自动采集开关",
    doorCollectCycle: "开关门自动采集周期",
    alarmFileSwitch: "报警文件自动上传",
    safetyHazardSwitch: "安全隐患报警开关",
    safetyHazardRecord: "安全隐患报警数据记录",
    read: "读取",
    set: "设置",
    dtuNoOnline: "终端不在线,不支持该操作",
  },
  alarm: {
    id: "报警id",
    mid: "设备序列号",
    code: "报警类型",
    value: "识别数量",
    description: "报警描述",
    picUrl: "图片",
    videoUrl: "视频",
    uploadTime: "报警时间",
  },
};
