<!--可输入或者选择的input组件-->
<template>
  <el-select
    v-model="internalValue"
    :placeholder="placeholder"
    clearable
    v-on="$listeners">
    <el-option
      v-for="item in options"
      :key="item.code"
      :label="item.desc"
      :value="item.code">
    </el-option>
  </el-select>
</template>
<script>
  import {mutations} from "@/store/store";

  export default {
    props: {
      value: String,
      type: {
        type: String,
        required: true,
      },
      placeholder: {
        type: String,
        default: "请选择",
      },
    },
    data() {
      return {
        options: {},
      };
    },
    computed: {
      internalValue: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit("input", v);
        },
      },
    },
    watch: {
      type() {
        this.getOptions();
        this.$emit("input", "");
      },
    },
    mounted() {
      this.getOptions();
    },
    methods: {
      getOptions() {
        mutations.getDicts().then(result => {
          this.options = this.$oc(() => result[this.type], {});
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
