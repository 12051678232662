<!--搜索框，自动添加label-->
<template>
  <div class="vm-search-com">
    <div :style="labelStyle" class="vm-search-label">
      {{label}}
    </div>
    <slot></slot>
  </div>
</template>

<script>

  export default {
    props: {
      label: {
        type: String,
        default: "　",
      },
      labelWidth: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
      };
    },
    computed: {
      labelStyle: function() {
        return {
          width: this.labelWidth === 0 ? "auto" : this.labelWidth + "px",
        };
      },
    },
    created() {
    },
  };
</script>

<style lang="scss" scoped>
  .vm-search-com {
    display: inline-flex;
    align-items: center;
    margin: 5px 0;

    .vm-search-label {
      font-size: 14px;
      color: #333;
      line-height: 26px;
      padding: 0 10px;
    }

    ::v-deep .el-select, .el-input {
      flex: 1;
    }

    ::v-deep .el-checkbox-group, ::v-deep .el-radio-group {
      background: white;
      padding: 4px 10px;
      border: 1px solid #e8e8e8;
      border-radius: 1px;
    }

    ::v-deep .el-checkbox__label, ::v-deep .el-radio__label {
      font-size: 13px;
    }

    ::v-deep .el-checkbox {
      margin-right: 15px;
    }
  }
</style>
