import Main from "@/views/main/Main.vue";
// @formatter:off
// 不作为Main组件的子页面展示的页面单独写，如下
export const otherRouter = [
  {path: "/login", name: "login", component: () => import("@/views/main/Login.vue")},
  {path: "/china", name: "china", component: () => import("@/views/main/China.vue")},
  // {path: "/kanban", name: "kanban", component: () => import("@/views/KanBan/KanBan.vue")},
  {path: "/board", name: "board", component: () => import("@/views/KanBan/Board.vue")},
  {path: "/private", name: "private", component: () => import("@/views/sysItem/Private.vue")},
  {
    path: "/singleMonitor/:elevatorId",
    name: "singleMonitor",
    component: () => import("@/views/monitor/SingleMonitor.vue"),
  },
  {path: "/elevator/:elevatorId", name: "elevatorQuery", component: () => import("@/views/elevator/ElevatorQuery.vue")},
  {
    path: "/workOrder/:workOrderId",
    name: "workOrderQuery",
    component: () => import("@/views/workOrder/WorkOrderQuery.vue"),
  },
  {path: "/jx/imageList", name: "JxImageList", component: () => import("@/views/workOrder/ImageList.vue")},
  {
    path: "/newMaintWorkOrder/:maintWorkOrderId",
    name: "newMaintWorkOrderQuery",
    component: () => import("@/views/newMaintWorkOrder/MaintWorkOrderQuery"),
  },
];

// 作为Main组件的子页面展示但是不在左侧菜单显示的路由写在otherRouter里
export const mainRouterNotInMenu = {
  path: "/",
  component: Main,
  children: [
    {path: "/", name: "home", meta: {title: "home"}, component: () => import("@/views/main/Home.vue")},
    {path: "account", name: "account", meta: {title: "account"}, component: () => import("@/views/user/Account.vue")},
  ],
};

// 作为Main组件的子页面展示并且在左侧菜单显示的路由写在appRouter里
export const mainRouterInMenu = [
  {
    path: "/elevatorInfo",
    meta: {title: "elevator", icon: "iot-elevator"},
    component: Main,
    children: [
      {
        path: "/elevatorNetApply",
        name: "elevatorNetApply",
        meta: {title: "elevatorNetApply", authIndex: 814},
        component: () => import("@/views/elevatorNetApply/List.vue"),
      },
      {
        path: "/elevator",
        name: "elevator",
        meta: {title: "elevatorInfo", authIndex: 110},
        component: () => import("@/views/elevator/ElevatorList.vue"),
      },
      {
        path: "/deviceGateway",
        name: "deviceGateway",
        meta: {title: "deviceGateway", authIndex: 9},
        component: () => import("@/views/deviceGateway/DeviceGatewayList"),
      },
      {
        path: "/elevatorSetting",
        name: "elevatorSetting",
        meta: {title: "elevatorSetting", authIndexes: [20, 24, 109]},
        component: () => import("@/views/elevator/elevatorSetting/ElevatorSettingList"),
      },
    ],
  },
  {
    path: "/elevatorMonitor",
    meta: {title: "monitor", icon: "iot-monitor"},
    component: Main,
    children: [
      {
        path: "/elevatorMonitor/singleLadderMonitor",
        name: "singleLadderMonitor",
        meta: {title: "singleMonitor", authIndex: 403},
        component: () => import("@/views/monitor/singleLadderMonitor.vue"),
      },
      // {
      //   path: "/kanban",
      //   name: "kanban",
      //   meta: {title: "kanban", newPage: true, authIndex: 406, onlyCnShow: true},
      // },
      {
        path: "/board",
        name: "board",
        meta: {title: "board", newPage: true, authIndex: 406, onlyCnShow: true},
      },
    ],
  },
  {
    path: "/maint",
    meta: {title: "event", icon: "iot-message"},
    component: Main,
    children: [
      {
        path: "/event",
        name: "deviceEvent",
        meta: {title: "mb", authIndex: 45},
        component: () => import("@/views/event/DeviceEventList.vue"),
      },
      {
        path: "/fault",
        name: "faultRecord",
        meta: {title: "faultRecords", authIndex: 117},
        component: () => import("@/views/event/FaultRecordList.vue"),
      },
    ],
  },
  {
    path: "/maint",
    meta: {title: "upkeep", icon: "iot-repair"},
    component: Main,
    children: [
      {
        path: "/contract",
        name: "contract",
        meta: {title: "contract", authIndex: 820},
        component: () => import("@/views/contract/ContractList"),
      },
      {
        path: "/workOrder",
        name: "workOrder",
        meta: {title: "repairJob", authIndex: 302},
        component: () => import("@/views/workOrder/WorkOrderList.vue"),
      },
      {
        path: "/maintWork",
        name: "newMaintWorkOrder",
        meta: {title: "maintenanceWorkOrder", authIndex: 322},
        component: () => import("@/views/newMaintWorkOrder/MaintWorkOrderList.vue"),
      },
      {
        path: "/yearCheck",
        name: "yearCheckRecord",
        meta: {title: "annualInspectionRecord", authIndex: 132},
        component: () => import("@/views/yearCheck/YearCheckList.vue"),
      },
      {
        path: "/safeCheck",
        name: "safeCheckOrder",
        meta: {title: "safeCheckOrder", authIndex: 827, onlyCnShow: true},
        component: () => import("@/views/safeCheckOrder/SafeCheckOrderList.vue"),
      },
      {
        path: "/maintenanceSetting",
        name: "maintenanceSetting",
        meta: {title: "maintenanceSetting", authIndexes: [326, 327, 328]},
        component: () => import("@/views/newMaintWorkOrder/maintSetting/maintBaseInfoList.vue"),
      },
    ],
  },
  {
    path: "/baseInfo",
    meta: {title: "baseInfo", icon: "iot-basic-info"},
    component: Main,
    children: [
      {
        path: "/useUnitCom",
        name: "useUnitCom",
        meta: {title: "useUnitCom", authIndex: 16},
        component: () => import("@/views/company/useUnitCom/UseUnitComList"),
      },
      {
        path: "/createCompany",
        name: "createCompany",
        meta: {title: "createCompany", authIndex: 12},
        component: () => import("@/views/company/createCom/CreateCompanyList"),
      },
      {
        path: "/propertyCompany",
        name: "propertyCompany",
        meta: {title: "propertyCompany", authIndex: 12},
        component: () => import("@/views/company/propertyCom/PropertyCompanyList"),
      },
      {
        path: "/maintCompany",
        name: "maintCompany",
        meta: {title: "maintCompany", authIndex: 12},
        component: () => import("@/views/company/maintCom/MaintCompanyList"),
      },
      {
        path: "/installCompany",
        name: "installCompany",
        meta: {title: "installCompany", authIndex: 12},
        component: () => import("@/views/company/installCom/InstallCompanyList"),
      },
      {
        path: "/changeCompany",
        name: "changeCompany",
        meta: {title: "changeCompany", authIndex: 12},
        component: () => import("@/views/company/changeCom/ChangeCompanyList"),
      },
    ],
  },
  {
    path: "/userRole",
    meta: {title: "userRole", icon: "iot-user"},
    component: Main,
    children: [
      {
        path: "/user",
        name: "user",
        meta: {title: "user", authIndex: 3},
        component: () => import("@/views/user/UserList.vue"),
      },
      {
        path: "/role",
        name: "role",
        meta: {title: "role", authIndex: 5},
        component: () => import("@/views/role/RoleList.vue"),
      },
    ],
  },
  {
    path: "/system",
    meta: {title: "system", icon: "iot-system"},
    component: Main,
    children: [
      {
        path: "/newMsgSend",
        name: "newMsgSend",
        meta: {title: "newMsgSend", authIndex: 816},
        component: () => import("@/views/system/MsgSendList"),
      },
      {
        path: "/userLoginLog",
        name: "userLoginLog",
        meta: {title: "userLoginLog", authIndex: 817},
        component: () => import("@/views/system/UserLoginLogList"),
      },
      {
        path: "/userOperateLog",
        name: "userOperateLog",
        meta: {title: "userOperateLog", authIndex: 818},
        component: () => import("@/views/system/UserOperateLogList"),
      },
      {
        path: "/systemSetting",
        name: "systemSetting",
        meta: {title: "systemSetting", authIndex: 801},
        component: () => import("@/views/system/SystemSettingEdit.vue"),
      },
    ],
  },
  // {
  //   path: "/maint",
  //   meta: {title: "accessPlatform", icon: "iot-service"},
  //   component: Main,
  //   children: [
  //     {
  //       path: "/shanghai",
  //       name: "shanghai",
  //       meta: {title: "shanghai", authIndex: 42, onlyCnShow: true},
  //       component: () => import("@/views/passport/ShanghaiList.vue"),
  //     },
  //     {
  //       path: "/huzhou",
  //       name: "huzhou",
  //       meta: {title: "huzhou", authIndex: 52, onlyCnShow: true},
  //       component: () => import("@/views/passport/HuZhouList"),
  //     },
  //     {
  //       path: "/chongqing",
  //       name: "chongqing",
  //       meta: {title: "chongqing", authIndex: 48, onlyCnShow: true},
  //       component: () => import("@/views/passport/chongqing/Chongqing.vue"),
  //     },
  //     {
  //       path: "/shenyang",
  //       name: "shenyang",
  //       meta: {title: "shenyang", authIndex: 49, onlyCnShow: true},
  //       component: () => import("@/views/passport/ShenyangList.vue"),
  //     },
  //     {
  //       path: "/fujian",
  //       name: "fujian",
  //       meta: {title: "fujian", authIndex: 58, onlyCnShow: true},
  //       component: () => import("@/views/passport/fujian/FujianList"),
  //     },
  //     {
  //       path: "/jiaxing",
  //       name: "jiaxing",
  //       meta: {title: "jiaxing", authIndex: 50, onlyCnShow: true},
  //       component: () => import("@/views/passport/jiaxing/JiaxingList"),
  //     },
  //     {
  //       path: "/guangxi",
  //       name: "guangxi",
  //       meta: {title: "guangxi", authIndex: 59, onlyCnShow: true},
  //       component: () => import("@/views/passport/guangxi/GuangxiList"),
  //     },
  //     {
  //       path: "/zhejiang",
  //       name: "zhejiang",
  //       meta: {title: "zhejiang", authIndex: 60, onlyCnShow: true},
  //       component: () => import("@/views/passport/zhejiang/ZhejiangList"),
  //     },
  //     {
  //       path: "/nanchang",
  //       name: "nanchang",
  //       meta: {title: "nanchang", authIndex: 55, onlyCnShow: true},
  //       component: () => import("@/views/passport/NanchangList"),
  //     },
  //     {
  //       path: "/shaoxing",
  //       name: "shaoxing",
  //       meta: {title: "shaoxing", authIndex: 53, onlyCnShow: true},
  //       component: () => import("@/views/passport/ShaoxingList"),
  //     },
  //     {
  //       path: "/ningbo",
  //       name: "ningbo",
  //       meta: {title: "ningbo", authIndex: 54, onlyCnShow: true},
  //       component: () => import("@/views/passport/ningbo/NingboList"),
  //     },
  //     {
  //       path: "/hangzhou",
  //       name: "hangzhou",
  //       meta: {title: "hangzhou", authIndex: 43, onlyCnShow: true},
  //       component: () => import("@/views/passport/HangzhouList"),
  //     },
  //     {
  //       path: "/liaoning",
  //       name: "liaoning",
  //       meta: {title: "liaoning", authIndex: 51, onlyCnShow: true},
  //       component: () => import("@/views/passport/LiaoningList"),
  //     },
  //   ],
  // },
  {
    path: "/maint",
    meta: {title: "Operations", icon: "iot-statistics"},
    component: Main,
    children: [
      {
        path: "/integration",
        name: "integration",
        meta: {title: "integration", onlySuperAdmin: true, onlyCnShow: true},
        component: () => import("@/views/integration/List"),
      },
      {
        path: "/uiSetting",
        name: "uiSetting",
        meta: {title: "uiSetting", onlySuperAdmin: true},
        component: () => import("@/views/system/UiSettingList.vue"),
      },
      {
        path: "/sysItem",
        name: "sysItem",
        meta: {title: "sysItem", onlySuperAdmin: true, onlyCnShow: true},
        component: () => import("@/views/sysItem/SysltemTab.vue"),
      },
      {
        path: "/msgTemplate",
        name: "msgTemplate",
        meta: {title: "msgTemplate", onlySuperAdmin: true, onlyCnShow: true},
        component: () => import("@/views/msgTemplate/MsgTemplateList.vue"),
      },
      {
        path: "/tenant",
        name: "tenant",
        meta: {title: "tenant", onlySuperAdmin: true},
        component: () => import("@/views/tenant/SettingList"),
      },
      {
        path: "/deviceGateway1",
        name: "deviceGateway1",
        meta: {title: "deviceGateway", onlySuperAdmin: true},
        component: () => import("@/views/deviceGateway/DeviceGatewayList2"),
      },
      {
        path: "/company",
        name: "company",
        meta: {title: "company", onlySuperAdmin: true, onlyCnShow: true},
        component: () => import("@/views/company/CompanyList.vue"),
      },
    ],
  },
];

// 所有上面定义的路由都要写在下面的routers里
export const routers = [...otherRouter, ...mainRouterInMenu, mainRouterNotInMenu];
