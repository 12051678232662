import Vue from "vue";
import axios from "./http/axios";
import http from "./http/http";
import drag from "./directive/el-dialog-drag";
import bus from "./bus";
import {Message} from "element-ui";
import rule from "@/util/rule";
import commonUtil from "@/util/commonUtil";
import tenant from "@/util/tenant";
import * as api from "@/plugins/http/commonApi";
import auth from "@/util/auth";
import {myI18n} from "@/lang";

Plugin.install = function(Vue) {
  Vue.directive("el-drag-dialog", drag);
  //event bus
  Vue.prototype.$bus = bus;
  // 网络请求
  Vue.prototype.$api = api;
  Vue.prototype.$http = http;
  Vue.prototype.$axios = axios;
  Vue.prototype.$util = commonUtil;
  Vue.prototype.$rule = rule;
  Vue.prototype.$tenant = tenant.get();
  Vue.prototype.$l = myI18n;
  Vue.prototype.$assign = function(target, source) {
    if (!source) {
      return;
    }
    Object.keys(target).map(key => {
      if (key in source) {
        let targetValue = target[key];
        let sourceValue = source[key];
        if (Array.isArray(targetValue)) {
          target[key] = sourceValue || [];
        } else if (typeof targetValue === "object") {
          this.$assign(targetValue, sourceValue);
        } else {
          target[key] = sourceValue;
        }
      }
    });
    return target;
  };
  //模拟Optional Chaining
  Vue.prototype.$oc = function(fn, defaultVal) {
    try {
      let result = fn();
      return result || defaultVal;
    } catch (e) {
      return defaultVal;
    }
  };
  //重置表单
  Vue.prototype.$reset = function(formRef, ...excludeFields) {
    let obj1 = this.$data;
    let obj2 = this.$options.data.call(this);
    if (!excludeFields || excludeFields.length === 0) {
      excludeFields = ["ruleValidate"];
    }
    for (let attrName in obj1) {
      if (excludeFields && excludeFields.includes(attrName)) {
        continue;
      }
      obj1[attrName] = obj2[attrName];
    }
    let ref = this.$refs[formRef || "form"];
    if (ref) {
      setTimeout(() => {
        ref.clearValidate();
      }, 500);
    }
  };
  // 复制obj2的数据到obj1
  Vue.prototype.$copy = function(obj1, obj2, ...excludeFields) {
    for (let attrName in obj1) {
      if (excludeFields && excludeFields.includes(attrName)) {
        continue;
      }
      obj1[attrName] = obj2[attrName];
    }
  };
  // 权限判断
  Vue.prototype.$auth = function(authIndex) {
    let permissions = auth.getAuth();
    return permissions && permissions.charAt(authIndex) === "1";
  };

  Vue.prototype.$auths = function(authIndexs) {
    for (let i = 0; i < authIndexs.length; i++) {
      let authIndex = authIndexs[i];
      let permissions = auth.getAuth();
      if (permissions && permissions.charAt(authIndex) === "1") {
        return true;
      }
    }
    return false;
  };
  //全局异常处理
  Vue.config.errorHandler = function(err, vm, info) {
    console.error(err, vm, info);
    Message.error(err);
  };
};
Vue.use(Plugin);

export default Plugin;
