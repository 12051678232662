import auth from "./auth";
import { Message } from "element-ui";

/**
 * 环信工具类
 */
const WebIM = window.WebIM;
let HX = function() {
  this.conn = null;
  this.rtcCall = null;
  this.isCalling = false;
  this.isConneted = false;
  this.callTimeout = 0;
  this.callStatusCallback = null;
};
HX.fn = HX.prototype;

HX.fn.init = function(statusCallback) {
  WebIM.config = window.hx_config;
  //初始化连接
  this.conn = WebIM.conn = new WebIM.default.connection({
    appKey: WebIM.config.appkey,
    isHttpDNS: WebIM.config.isHttpDNS,
    isMultiLoginSessions: WebIM.config.isMultiLoginSessions,
    host: WebIM.config.Host,
    https: WebIM.config.https,
    url: WebIM.config.xmppURL,
    apiUrl: WebIM.config.apiURL,
    isAutoLogin: true,
    heartBeatWait: WebIM.config.heartBeatWait,
    autoReconnectNumMax: WebIM.config.autoReconnectNumMax,
    autoReconnectInterval: WebIM.config.autoReconnectInterval,
    isStropheLog: WebIM.config.isStropheLog,
    delivery: WebIM.config.delivery,
  });
  this.conn.listen({
    onOpened: function(message) {
      statusCallback(1);
      log("onOpened-连接已成功建立", message);
    },
    onClosed: function(message) {
      statusCallback(0);
      log("onClosed-连接关闭回调", message);
    },
    onOnline: function() {
      log("onOnline-本机网络连接成功");
    },
    onOffline: function() {
      log("onOffline-本机网络掉线");
    },
    onError: function(message) {
      log("onError-失败回调", message);
      if (message && message.type === 206) {
        statusCallback(3);
      } else {
        statusCallback(0);
      }
    },
  });
  //初始化 WebRTC Call
  this.rtcCall = new WebIM.WebRTC.Call({
    connection: this.conn,
    mediaStreamConstaints: {
      audio: true,
      video: true,
    },
    listener: {
      onAcceptCall: (from, options) => {
        log("onAcceptCall::", "from: ", from, "options: ", options);
      },
      //通过streamType区分视频流和音频流，streamType: 'VOICE'(音频流)，'VIDEO'(视频流)
      onGotRemoteStream: (stream, streamType) => {
        log("onGotRemoteStream::", "stream: ", stream, "streamType: ", streamType);
        let video = document.getElementById("remoteVideo");
        video.srcObject = stream;
      },
      onGotLocalStream: (stream, streamType) => {
        log("onGotLocalStream::", "stream:", stream, "streamType: ", streamType);
        let video = document.getElementById("localVideo");
        video.srcObject = stream;
      },
      onRinging: (caller, streamType) => {
        log("onRinging", caller, streamType);
      },
      onTermCall: (reason) => {
        log("onTermCall::reason:", reason);
        this.isCalling = false;
        if (reason === "busy") {
          Message.error("对方正在通话中");
        } else if (reason === "hangup") {
          Message.error("对方挂断");
        } else if (reason === "reject") {
          Message.error("对方拒绝");
        } else {
          Message.error(reason);
        }
        this.callStatusCallback("termCall");
      },
      onIceConnectionStateChange: (iceState) => {
        log("onIceConnectionStateChange::", "iceState:", iceState);
        if (iceState === "connected") {
          this.isConneted = true;
          this.isCalling = true;
          this.callStatusCallback(iceState);
        }
      },
      onError: (e) => {
        log("onError", e);
        if (e.message === "callee is not online!") {
          this.isCalling = false;
          Message.error("对方不在线");
          this.callStatusCallback("calleeOffline");
        } else if (e.event && e.event.name === "NotAllowedError") {
          console.error("视频通话访问摄像头或者麦克风被用户禁止");
        } else {
          this.isCalling = false;
          Message.error(e.message);
          this.callStatusCallback("onError");
        }
      },
    },
  });
  //登录
  let options = {
    apiUrl: WebIM.config.apiURL,
    user: auth.getImUsername(),
    pwd: auth.getImPassword(),
    appKey: WebIM.config.appkey,
  };
  log("开始登录", options);
  this.conn.open(options);
  //浏览器刷新关闭时结束通话
  window.addEventListener("beforeunload", e => {
    this.hangup();
  });
};

/**
 * callStatusCallback:calling,hangup
 * type:audio或者video
 * 发起音视频呼叫
 */
HX.fn.doCalling = function(type, account, callStatusCallback) {
  log("发起音视频呼叫", type, account);
  if (this.isCalling) {
    Message.error("您正在通话中");
    callStatusCallback("calling");
    return;
  }
  this.callStatusCallback = callStatusCallback;
  this.isCalling = true;
  this.rtcCall.caller = auth.getImUsername();
  if (type === "audio") {
    this.rtcCall.makeVoiceCall(account);
  } else {
    this.rtcCall.makeVideoCall(account);
  }
  //检测是否接通，否则超时
  clearTimeout(this.callTimeout);
  this.callTimeout = setTimeout(() => {
    log("超时执行线程进入", "this.isConneted:" + this.isConneted + ",this.isCalling:" + this.isCalling);
    if (!this.isConneted && this.isCalling) {
      Message.error("呼叫超时");
      this.isCalling = false;
      this.hangup();
    }
  }, 20000);
};

/**
 * 挂断
 */
HX.fn.hangup = function() {
  log("hx-挂断");
  try {
    this.rtcCall.endCall();
  } catch (e) {
    console.log(e.message);
  }
  if (this.callStatusCallback) {
    this.callStatusCallback("hangup");
  }
};

let log = (title, ...content) => {
  console.log(`%c hx-${title}`, "color: green", ...content);
};

const huanxin = new HX();

export default huanxin;
