<template>
  <div class="vm-main">
    <sidebar class="vm-main-sidebar"></sidebar>
    <header-bar class="vm-main-header" :class="{'vm-header-collapsed':sidebarCollapsed}"></header-bar>
    <div class="vm-main-page" :class="{'vm-main-collapsed':sidebarCollapsed}">
      <div class="vm-content">
        <router-view class="vm-page"></router-view>
      </div>
    </div>
  </div>
</template>
<script>
  import Sidebar from "./Sidebar.vue";
  import HeaderBar from "./HeaderBar.vue";
  import {mapGetters} from "vuex";
  import * as types from "../../store/mutation-types.js";

  export default {
    components: {
      Sidebar, HeaderBar,
    },
    computed: mapGetters({
      sidebarCollapsed: "sidebarCollapsed",
    }),
    mounted() {
      // 生成菜单
      this.$store.commit(types.INIT_MENUS);
    },
  };
</script>
<style lang="scss">
@import "../../style/variables.scss";

.vm-main {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;

  .vm-main-sidebar {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  .vm-main-header {
    box-sizing: border-box;
    position: relative;
    left: $sidebar-width;
    width: calc(100% - #{$sidebar-width});
    height: $header-height;
    transition: all .3s;
    background-color: #f2f2f2;
  }

  .vm-main-page {
    position: absolute;
    top: $header-height;
    left: $sidebar-width;
    background-color: #f2f2f2;
    right: 0;
    bottom: 0;
    padding: 0 16px;
    overflow: auto;
    transition: left .3s;

    .vm-content {
      height: 100%;
      width: 100%;
      min-width: 1100px;
    }
  }

  .vm-header-collapsed {
    left: $sidebar-width-close;
    width: calc(100% - #{$sidebar-width-close});
  }

  .vm-main-collapsed {
    left: $sidebar-width-close;
  }

}
</style>
