import Vue from "vue";
import VmTable from "@/components/refactor/VmTable";
import VmSearch from "@/components/refactor/VmSearch";
import VmSelect from "@/components/refactor/VmSelect";
import VmSelectInput from "@/components/refactor/VmSelectInput";
import VmDictSelect from "@/components/refactor/VmDictSelect";

Vue.component("VmTable", VmTable);
Vue.component("VmSearch", VmSearch);
Vue.component("VmSelect", VmSelect);
Vue.component("VmSelectInput", VmSelectInput);
Vue.component("VmDictSelect", VmDictSelect);


