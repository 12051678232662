import Vue from "vue";
import Vuex from "vuex";
import {mainRouterInMenu} from "@/router/router.js";
import * as types from "./mutation-types";
import auth from "@/util/auth";
import i18n from "@/lang";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    menus: [],
    routers: [],
    permissions: [],
    sidebarCollapsed: false,
    webIMConn: {},
  },
  getters: {
    menus: state => state.menus,
    routers: state => state.routers,
    sidebarCollapsed: state => state.sidebarCollapsed,
    permissions: state => state.permissions,
    webIMConn: state => state.webIMConn,
  },
  mutations: {
    [types.TOGGLE_SIDEBAR](state) {
      state.sidebarCollapsed = !state.sidebarCollapsed;
    },
    [types.INIT_MENUS](state) {
      let username = auth.getUsername();
      state.menus = [];
      mainRouterInMenu.forEach((item, index) => {
        let menu = {
          title: item.meta.title,
          icon: item.meta.icon,
          children: [],
        };
        item.children.forEach((childItem, childIndex) => {
          let childMenu = {
            title: childItem.meta.title,
            name: childItem.name,
            path: childItem.path,
            newPage: childItem.meta.newPage,
          };
          let authIndexes = childItem.meta.authIndexes;
          let authIndex = childItem.meta.authIndex;
          let onlyCnShow = !!childItem.meta.onlyCnShow;
          let onlySuperAdmin = !!childItem.meta.onlySuperAdmin;
          let onlyStep = !!childItem.meta.onlyStep;
          if (!onlyCnShow || i18n.isCn) {
            if (username === "superAdmin" || username === "admin" || authIndex === -1 || (authIndex && Vue.prototype.$auth(authIndex)) || (authIndexes && Vue.prototype.$auths(authIndexes))) {
              if (!onlyStep || window.config.siteName === "G-Cloud电梯云") {
                if (!onlySuperAdmin || username === "superAdmin") {
                  menu.children.push(childMenu);
                }
              }
            }
          }
        });
        if (menu.children.length > 0) {
          state.menus.push(menu);
        }
      });
    },
    [types.SET_ROUTERS](state, data) {
      state.routers[0].children = data;
    },
    [types.SET_PERMISSIONS](state, data) {
      state.permissions = data;
    },
  },
});

export default store;
